import { jsx as _jsx } from "react/jsx-runtime";
import { Tab as MuiTab, useMediaQuery } from '@mui/material';
const tabStyleInactive = {
    width: 204,
    height: 46,
    background: 'rgb(200, 200, 200)',
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
    border: '1px #E0E0E0 solid',
    color: 'rgba(0, 0, 0, 0.80)',
    fontSize: 16,
    fontFamily: 'Poppins',
    fontWeight: 600,
    letterSpacing: 0.15,
    wordWrap: 'break-word',
};
const tabStyleActive = {
    width: 197,
    height: 62.92,
    background: 'white',
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
    border: '1px #E0E0E0 solid',
    textAlign: 'center',
    color: 'black',
    fontSize: 16,
    fontFamily: 'Poppins',
    fontWeight: '600',
    letterSpacing: 0.15,
    wordWrap: 'break-word',
};
function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `TabContent-${index}`,
    };
}
export const Tab = ({ label, onChange, value, isActive }) => {
    const mediumDesktopSize = useMediaQuery('(min-width: 1440px)');
    if (!mediumDesktopSize) {
        tabStyleActive.width = 'auto';
        tabStyleActive.padding = '1px 3px';
        tabStyleInactive.width = 'auto';
        tabStyleInactive.padding = '1px 3px';
    }
    else {
        tabStyleActive.width = 197;
        tabStyleActive.padding = '12px 16px';
        tabStyleInactive.width = 204;
        tabStyleInactive.padding = '12px 16px';
    }
    return (_jsx(MuiTab, { label: label, sx: { ...(isActive ? tabStyleActive : tabStyleInactive) }, ...a11yProps(value), onChange: (event) => onChange(event, value), value: value }));
};
