import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Box } from '@mui/material';
import { Tab } from '../../Atomos/Tab';
import { TabContent } from '../TabContent';
export const Tabs = ({ tabs, boxStyle }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (_jsxs(Box, { sx: { height: '100%', ...boxStyle }, children: [_jsx(Box, { display: 'flex', alignItems: 'flex-end', children: tabs.map((tab, index) => (_jsx(Tab, { label: tab.tabTitle, onChange: (event) => handleChange(event, index), value: index, isActive: index === value }, index))) }), tabs.map((tab, index) => (_jsx(TabContent, { value: value, index: index, children: tab.tabChild }, index)))] }));
};
