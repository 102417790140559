import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, List, ListItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; // Ícone de erro
export default function ErrorModal({ errorCode, modalIsOpen, onClose, errorMessage, errors, errorsDetails }) {
    const title = errorCode === 422 || errorMessage === 'Prazo final da etapa já passou!' || errorMessage === 'Ocorreu um problema. Verifique os dados e tente novamente' || errorsDetails ? 'Atenção' : 'Erro';
    const mainColor = errorCode === 422 || errorMessage === 'Prazo final da etapa já passou!' || errorMessage === 'Ocorreu um problema. Verifique os dados e tente novamente' || errorsDetails ? 'orange' : 'red';
    const mainErrorMessage = errorCode === 422 ? 'Quase lá! Parece que alguns campos precisam de uma pequena revisão.' : errorMessage;
    const subErrorMessage = errorCode === 422 ? 'Campos a revisar:' : 'Detalhes do erro:';
    function parseErrorMessage(error) {
        const { msg, param } = error;
        const paramParts = param.split('.');
        const section = paramParts[1];
        const subSection = paramParts.length > 3 ? paramParts[2] : undefined;
        const field = paramParts.length > 3 ? paramParts[3] : paramParts[2];
        const errorMsg = msg.replace(`${param} `, '');
        return {
            section,
            subSection,
            field,
            errorMsg,
        };
    }
    return (_jsxs(Dialog, { open: modalIsOpen, onClose: onClose, PaperProps: {
            sx: {
                overflow: 'auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'fit-content',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                padding: '2rem 1.5rem',
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            },
        }, BackdropProps: {
            sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fundo escuro com transparência
            },
        }, children: [_jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', children: [_jsxs(DialogTitle, { sx: { flex: 1, textAlign: 'center', padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }, children: [_jsx(ErrorOutlineIcon, { sx: { fontSize: 32, color: mainColor, marginRight: '8px' } }), _jsx(Typography, { variant: 'h6', component: 'div', sx: { fontWeight: 'bold', color: mainColor }, children: title })] }), _jsx(IconButton, { onClick: onClose, sx: { position: 'absolute', top: 8, right: 8, color: '#000000' }, children: _jsx(CloseIcon, {}) })] }), _jsxs(DialogContent, { sx: { textAlign: 'center', paddingTop: '16px' }, children: [_jsx(Typography, { variant: 'body1', sx: { marginBottom: '16px', fontSize: '16px', fontWeight: '500' }, children: mainErrorMessage }), errorsDetails && (_jsx(Box, { textAlign: 'start', pt: 3, children: _jsxs(Typography, { sx: { fontSize: "12px" }, children: ["Detalhes: ", errorsDetails] }) })), errors && errors.length > 0 && (_jsxs(Box, { sx: { textAlign: 'left', marginTop: '24px' }, children: [_jsx(Typography, { variant: 'subtitle1', sx: { fontWeight: 'bold', marginBottom: '8px' }, children: subErrorMessage }), _jsx(List, { sx: { paddingLeft: '16px' }, children: errors.map((error, index) => (_jsx(ListItem, { sx: { paddingBottom: '8px' }, children: error.param ? (_jsxs(Box, { children: ["Se\u00E7\u00E3o: ", parseErrorMessage(error).section, _jsx("br", {}), parseErrorMessage(error).subSection
                                                ? `${parseErrorMessage(error).section === 'rh' || parseErrorMessage(error).section === 'financas'
                                                    ? 'Tipo: '
                                                    : 'Produto: '}${parseErrorMessage(error).subSection}`
                                                : null, parseErrorMessage(error).subSection ? _jsx("br", {}) : null, "Campo: ", parseErrorMessage(error).field, _jsx("br", {}), "Descri\u00E7\u00E3o: ", parseErrorMessage(error).errorMsg] })) : (error.msg) }, index))) })] }))] })] }));
}
