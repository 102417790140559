import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, createContext, useContext } from 'react';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
// Contexto para compartilhar dados entre as tabs
const TabContext = createContext(undefined);
export function useTabContext() {
    const context = useContext(TabContext);
    if (!context) {
        throw new Error('useTabContext must be used within a TabContext.Provider');
    }
    return context;
}
function TabContent({ children, value, index, ...other }) {
    return (_jsx("div", { role: 'menuitem', hidden: value !== index, id: `TabContent-${index}`, "aria-labelledby": `tab-${index}`, ...other, children: value === index && _jsx(Box, { p: 3, children: children }) }));
}
function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `TabContent-${index}`,
    };
}
const tabStyle = {
    width: 204,
    height: 46,
    background: '#F0EFEF',
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
    border: '1px #E0E0E0 solid',
    color: 'rgba(0;0;0;0.50)',
    fontSize: '16',
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '28',
    letterSpacing: '0.15',
    wordWrap: 'break-word',
};
const appBarStyle = {
    background: '#F0EFEF',
};
export default function TabTable2({ tabs }) {
    const [value, setValue] = useState(0);
    const [tabStorage, setTabStorage] = useState({});
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const updateTabStorage = (key, valueParam) => {
        setTabStorage((prev) => ({ ...prev, [key]: valueParam }));
    };
    return (_jsx(TabContext.Provider, { value: { tabStorage, updateTabStorage }, children: _jsxs(Box, { children: [_jsx(AppBar, { position: 'static', sx: { ...appBarStyle }, children: _jsx(Tabs, { value: value, onChange: handleChange, "aria-label": 'dynamic tabs', children: tabs.map((tab, index) => (_jsx(Tab, { label: tab.tabTitle, sx: { ...tabStyle }, ...a11yProps(index) }, index))) }) }), tabs.map((tab, index) => (_jsx(TabContent, { value: value, index: index, children: tab.tabChild }, index)))] }) }));
}
