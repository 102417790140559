import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import { ChatDrawer, ChatButton } from '../../Atomos';
const initialTooltipStyle = {
    fontSize: '14px',
    fontWeight: '600',
    background: `radial-gradient(circle at 50% 50%, #000A17 0%, #007535 100%), 
  linear-gradient(0deg, #000A17, #000A17)`,
    border: '1px solid rgba(255, 255, 255, 0.15)',
    color: '#fff',
    padding: '10px',
    borderRadius: '8px',
};
export default function Chat({ buttonStyle, drawerStyle, tooltipStyle, chatButtonImage, onClick, }) {
    const [open, setOpen] = useState(false);
    const buttonRef = useRef(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const handleToggle = () => {
        if (onClick) {
            onClick(); // Se existir um onClick externo, ele substitui o handleToggle.
        }
        else {
            if (!open && buttonRef.current) {
                const rect = buttonRef.current.getBoundingClientRect();
                setPosition({
                    top: rect.top - 550,
                    left: rect.left - 360,
                });
            }
            setOpen(!open);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { componentsProps: {
                    tooltip: {
                        sx: { ...initialTooltipStyle, ...tooltipStyle },
                    },
                }, title: 'Tem d\u00FAvidas? Pergunte \u00E0 XpertIA', arrow: true, placement: 'top-start', children: _jsx(ChatButton, { buttonStyle: buttonStyle, chatButtonImage: chatButtonImage, onClick: handleToggle, ref: buttonRef }) }), _jsx(ChatDrawer, { drawerStyle: drawerStyle, open: open, onClose: handleToggle, position: position })] }));
}
