import { jsx as _jsx } from "react/jsx-runtime";
import { Select, MenuItem, Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const textStyle = {
    color: 'rgba(0, 0, 0, 0.80)',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px' /* 186.667% */,
    letterSpacing: '0.15px',
};
export const DropdownSelect = ({ label, options, dropdownValue, onSelect, boxStyle, disabled }) => {
    const handleChange = (event) => {
        const value = event.target.value;
        const option = options.find((opt) => opt.value === value) || null;
        if (option) {
            onSelect(option);
        }
    };
    return (_jsx(Box, { sx: {
            display: 'flex',
            alignItems: 'start',
            alignSelf: 'stretch',
            justifyContent: 'space-between',
            borderRadius: '5px',
            backgroundColor: disabled ? 'rgb(215, 215, 215)' : '#EEE',
            cursor: disabled ? 'auto' : 'pointer',
            width: '200px',
            ...boxStyle,
        }, children: _jsx(Select, { value: dropdownValue?.value, onChange: handleChange, displayEmpty: true, disabled: disabled, IconComponent: ArrowDropDownIcon, inputProps: {
                sx: {
                    padding: 0,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                },
            }, sx: {
                '& .MuiSelect-select': {
                    padding: '14px 11px 14px 11px',
                    fontSize: '14px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                '& .MuiSvgIcon-root': {
                    right: 10,
                },
                '&.Mui-disabled': {
                    backgroundColor: 'rgb(215, 215, 215)',
                    '&::after': {
                        width: '0%',
                    },
                },
                width: '100%',
                backgroundColor: 'transparent',
            }, children: options.map((option, index) => (_jsx(MenuItem, { value: option.value, children: _jsx(Typography, { sx: { ...textStyle }, children: option.label }) }, index))) }) }));
};
