import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { Box1, InputWithTitle, SelectWithTitle } from '../../index';
import { scaleValue, Formarters } from '../../utils'; // Função scaleValue
export default function FinanceContainer({ rowOne, rowTwo, rowThree, onChange, scale = 1, isTimeOver }) {
    const [values, setValues] = useState({});
    const handleChangeInput = (key, value, error) => {
        // Atualiza o valor do input no estado
        const newValues = { ...values, [key]: value };
        // Reseta o select para o valor padrão se o valor do input alterar
        if (key === rowTwo.valueInputOne.key) {
            const defaultOption = rowTwo.valueInputTwo[0];
            newValues[defaultOption.key ?? defaultOption.label] = defaultOption.value;
        }
        // Reseta o select para o valor padrão se o valor do input alterar
        if (key === rowThree.valueInputOne.key) {
            const defaultOption = rowThree.valueInputTwo[0];
            newValues[defaultOption.key ?? defaultOption.label] = defaultOption.value;
        }
        setValues(newValues);
        onChange(newValues, error);
    };
    const handleChangeSelect = (option) => {
        const updatedValues = { ...values, [option.key ?? option.label]: option.value };
        setValues(updatedValues);
        onChange(updatedValues, null);
    };
    function renderSelectTwo(options) {
        const v = values[rowTwo.valueInputOne.key] === undefined
            ? Number.parseInt(rowTwo.valueInputOne.value)
            : Formarters.parseNumberWithComma(values[rowTwo.valueInputOne.key]);
        const isZeroRowValue = v === 0 || Formarters.parseNumberWithComma(Number.isNaN(v));
        function findDefaultOption() {
            // reason of options[1] is because the options[0] is sliced for the component, so the default value is the options[1]
            // so options[0] is likewise options[1]
            let o = options[1];
            o = options.find((option) => Number.parseInt(option.value) === Number.parseInt(options[1].defaultValue));
            return o;
        }
        if (isZeroRowValue) {
            return (_jsx(SelectWithTitle, { title: 'Prazo de Pagamento', tooltipText: '(\u00C9 uma aplica\u00E7\u00E3o com prazo determinado para saque e com taxa de juros pr\u00E9-fixada.) tempo em que o valor da Aplica\u00E7\u00E3o 2 ficar\u00E1 investido (2 ou 3 Etapas).', options: [options[0]], option: options[0], disabled: isTimeOver, onSelect: handleChangeSelect }));
        }
        return (_jsx(SelectWithTitle, { title: 'Prazo de Pagamento', tooltipText: '(\u00C9 uma aplica\u00E7\u00E3o com prazo determinado para saque e com taxa de juros pr\u00E9-fixada.) tempo em que o valor da Aplica\u00E7\u00E3o 2 ficar\u00E1 investido (2 ou 3 Etapas).', options: options.slice(1), option: findDefaultOption(), disabled: isTimeOver, onSelect: handleChangeSelect }));
    }
    function renderSelectThree(options) {
        const v = values[rowThree.valueInputOne.key] === undefined
            ? Number.parseInt(rowThree.valueInputOne.value)
            : Formarters.parseNumberWithComma(values[rowThree.valueInputOne.key]);
        const isZeroRowValue = v === 0 || Formarters.parseNumberWithComma(Number.isNaN(v));
        function findDefaultOption() {
            // reason of options[1] is because the options[0] is sliced for the component, so the default value is the options[1]
            // so options[0] is likewise options[1]
            let o = options[1];
            o = options.find((option) => Number.parseInt(option.value) === Number.parseInt(options[1].defaultValue));
            return o;
        }
        if (isZeroRowValue) {
            return (_jsx(SelectWithTitle, { title: 'Prazo de Pagamento', tooltipText: 'Tempo para a previs\u00E3o de pagamento do empr\u00E9stimo solicitado (2 ou 3 Etapas).', options: [options[0]], option: options[0], disabled: isTimeOver, onSelect: handleChangeSelect }));
        }
        return (_jsx(SelectWithTitle, { title: 'Prazo de Pagamento', tooltipText: 'Tempo para a previs\u00E3o de pagamento do empr\u00E9stimo solicitado (2 ou 3 Etapas).', options: options.slice(1), option: findDefaultOption(), disabled: isTimeOver, onSelect: handleChangeSelect }));
    }
    return (_jsx(Box1, { display: 'flex', flexDirection: 'column', sx: {
            height: 'auto',
            width: '95%',
            boxSizing: 'border-box',
            padding: scaleValue(scale, '23px 40px'),
        }, children: _jsxs(Box, { display: 'flex', flexDirection: 'column', gap: scaleValue(scale, '32px'), marginTop: scaleValue(scale, '12px'), children: [_jsxs(Box, { display: 'flex', flexDirection: 'column', sx: { width: '100%' }, children: [_jsx(Typography, { sx: {
                                width: '100%',
                                color: 'black',
                                fontSize: scaleValue(scale, 22),
                                fontFamily: 'Poppins',
                                fontWeight: '700',
                                letterSpacing: 0.15,
                                wordWrap: 'break-word',
                            }, children: "Aplica\u00E7\u00E3o 1" }), _jsxs(Box, { display: 'flex', gap: scaleValue(scale, '21px'), sx: { justifyContent: 'space-between' }, children: [_jsx(InputWithTitle, { title: 'Dep\u00F3sito($)', tooltipText: '(Pode ser considerada como similar ao investimento numa poupan\u00E7a.) valor a ser depositado na Aplica\u00E7\u00E3o 1.', initialAdornment: '$', disabled: rowOne.valueInputOne.disabled, validator: rowOne.valueInputOne.validator, helperText: rowOne.valueInputOne.helperText, onChange: (event, error) => handleChangeInput(rowOne.valueInputOne.key, event.target.value, error), defaultValue: rowOne.valueInputOne.value }), _jsx(InputWithTitle, { title: 'Saque($)', tooltipText: '(Pode ser considerada como similar ao investimento numa poupan\u00E7a.) valor do saque da Aplica\u00E7\u00E3o 1.', initialAdornment: '$', disabled: rowOne.valueInputTwo.disabled, validator: rowOne.valueInputTwo.validator, helperText: rowOne.valueInputTwo.helperText, onChange: (event, error) => handleChangeInput(rowOne.valueInputTwo.key, event.target.value, error), defaultValue: rowOne.valueInputTwo.value })] })] }), _jsxs(Box, { display: 'flex', flexDirection: 'column', sx: { width: '100%' }, children: [_jsx(Typography, { sx: {
                                width: '100%',
                                color: 'black',
                                fontSize: scaleValue(scale, 22),
                                fontFamily: 'Poppins',
                                fontWeight: '700',
                                letterSpacing: 0.15,
                                wordWrap: 'break-word',
                            }, children: "Aplica\u00E7\u00E3o 2" }), _jsxs(Box, { display: 'flex', gap: scaleValue(scale, '21px'), children: [_jsx(InputWithTitle, { title: 'Dep\u00F3sito($)', tooltipText: '(\u00C9 uma aplica\u00E7\u00E3o com prazo determinado para saque e com taxa de juros pr\u00E9-fixada.) valor a ser depositado na Aplica\u00E7\u00E3o 2.', initialAdornment: '$', disabled: rowTwo.valueInputOne.disabled, validator: rowTwo.valueInputOne.validator, helperText: rowTwo.valueInputOne.helperText, onChange: (event, error) => handleChangeInput(rowTwo.valueInputOne.key, event.target.value, error), defaultValue: rowTwo.valueInputOne.value }), renderSelectTwo(rowTwo.valueInputTwo)] })] }), _jsxs(Box, { display: 'flex', flexDirection: 'column', sx: { width: '100%' }, children: [_jsx(Typography, { sx: {
                                width: '100%',
                                color: 'black',
                                fontSize: scaleValue(scale, 22),
                                fontFamily: 'Poppins',
                                fontWeight: '700',
                                letterSpacing: 0.15,
                                wordWrap: 'break-word',
                            }, children: "Empr\u00E9stimo Normal" }), _jsxs(Box, { display: 'flex', gap: scaleValue(scale, '21px'), children: [_jsx(InputWithTitle, { title: 'Dep\u00F3sito($)', tooltipText: 'Valor a ser tomado de empr\u00E9stimo (verifique as taxas).', initialAdornment: '$', disabled: rowThree.valueInputOne.disabled, validator: rowThree.valueInputOne.validator, helperText: rowThree.valueInputOne.helperText, onChange: (event, error) => handleChangeInput(rowThree.valueInputOne.key, event.target.value, error), defaultValue: rowThree.valueInputOne.value }), renderSelectThree(rowThree.valueInputTwo)] })] })] }) }));
}
