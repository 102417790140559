import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { scaleValue } from '../../utils';
import Box1 from '../../Atomos/Box';
import { ArrowButton } from '../../Atomos';
const scale = 1;
const scaledBoxStyle = {
    width: scaleValue(scale, 302 - 24 * 2),
    height: scaleValue(scale, 885 - 38 * 2),
    background: 'white',
    border: '1px #E0E0E0 solid',
    padding: `${scaleValue(scale, 38)}px ${scaleValue(scale, 24)}px`,
    alignItems: 'center',
    gap: '12px',
    position: 'relative',
};
export default function RightContainer({ boxStyle }) {
    return (_jsxs(Box, { display: 'flex', flexDirection: 'column', sx: { ...scaledBoxStyle, ...boxStyle }, children: [_jsx(Typography, { sx: { color: 'black', fontSize: '24px', fontFamily: 'Poppins', fontWeight: '700', letterSpacing: 0.15, wordWrap: 'break-word' }, children: "Jornal" }), _jsxs(Box1, { display: 'flex', flexDirection: 'column', sx: { height: 'auto', width: 'auto', padding: '4px', alignItems: 'center' }, children: [_jsx(Typography, { sx: {
                            textAlign: 'center',
                            color: 'black',
                            fontSize: '16px',
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                        }, children: "Noticia 1" }), _jsx(Typography, { sx: {
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                            color: 'black',
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            fontWeight: '400',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                        }, children: "Morem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing." })] }), _jsxs(Box1, { display: 'flex', flexDirection: 'column', sx: { height: 'auto', width: 'auto', padding: '4px', alignItems: 'center' }, children: [_jsx(Typography, { sx: {
                            textAlign: 'center',
                            color: 'black',
                            fontSize: '16px',
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                        }, children: "Noticia 2" }), _jsx(Typography, { sx: {
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                            color: 'black',
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            fontWeight: '400',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                        }, children: "Morem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing." })] }), _jsxs(Box1, { display: 'flex', flexDirection: 'column', sx: { height: 'auto', width: 'auto', padding: '4px', alignItems: 'center' }, children: [_jsx(Typography, { sx: {
                            textAlign: 'center',
                            color: 'black',
                            fontSize: '16px',
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                        }, children: "Noticia 3" }), _jsx(Typography, { sx: {
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                            color: 'black',
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            fontWeight: '400',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                        }, children: "Morem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing." })] }), _jsxs(Box1, { display: 'flex', flexDirection: 'column', sx: { height: 'auto', width: 'auto', padding: '4px', alignItems: 'center' }, children: [_jsx(Typography, { sx: {
                            textAlign: 'center',
                            color: 'black',
                            fontSize: '16px',
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                        }, children: "Noticia 4" }), _jsx(Typography, { sx: {
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                            color: 'black',
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            fontWeight: '400',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                        }, children: "Morem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing." })] }), _jsx(ArrowButton, { direction: 'left', buttonStyle: { position: 'absolute', left: '-7%', top: '45%' } })] }));
}
