import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import Box1 from '../../Atomos/Box';
import SatisfationMeter from '../../Atomos/SatisfationMeter';
import { scaleValue } from '../../utils'; // Importando a função scaleValue
export default function SatisfationMeterBox({ title, angryEmoji, neutralEmoji, happyEmoji, percentage, boxStyle, scale = 1, }) {
    const textStyle = {
        color: 'black',
        fontSize: scaleValue(scale, 16, 0.2),
        fontFamily: 'Poppins',
        fontWeight: '700',
        letterSpacing: scaleValue(scale, 0.15),
        wordWrap: 'break-word',
        width: '100%',
        textAlign: 'center',
    };
    const horizontalPadding = scaleValue(scale, 77, 1); // Aplicando o scaleValue para horizontalPadding
    const verticalPadding = scaleValue(scale, 4, 1); // Aplicando o scaleValue para verticalPadding
    return (_jsxs(Box1, { display: 'flex', flexDirection: 'column', sx: { padding: `${verticalPadding}px ${horizontalPadding}px`, ...boxStyle }, children: [_jsx(Typography, { style: { ...textStyle }, children: title }), _jsx(SatisfationMeter, { percentage: percentage, scale: scale }), _jsxs(Box, { display: 'flex', width: '100%', justifyContent: 'space-between', children: [_jsx("div", { style: {
                            height: scaleValue(scale, '30px'),
                            color: 'white',
                            fontSize: scaleValue(scale, 24, 0.2),
                            fontFamily: 'Poppins',
                            fontWeight: '700',
                            letterSpacing: scaleValue(scale, 0.15),
                            wordWrap: 'break-word',
                        }, children: angryEmoji }), _jsx("div", { style: {
                            height: scaleValue(scale, '30px'),
                            color: 'white',
                            fontSize: scaleValue(scale, 24, 0.2),
                            fontFamily: 'Poppins',
                            fontWeight: '700',
                            letterSpacing: scaleValue(scale, 0.15),
                            wordWrap: 'break-word',
                        }, children: neutralEmoji }), _jsx("div", { style: {
                            height: scaleValue(scale, '30px'),
                            color: 'white',
                            fontSize: scaleValue(scale, 24, 0.2),
                            fontFamily: 'Poppins',
                            fontWeight: '700',
                            letterSpacing: scaleValue(scale, 0.15),
                            wordWrap: 'break-word',
                        }, children: happyEmoji })] })] }));
}
