import { Box } from '@mui/material';
import React from 'react';
import { TabTable } from '@repo/ui';
import { FolhaDecisaoDTO } from '../../../dto';
import { EtapaDTO } from '../../../dto/etapaDTO';
import { GameDetailsDTO } from '../../../dto/gameDetailsDTO';
import MyStockContent from './MyStockContent';
import MidContainerContent from './MidContainerContent';
import ReportContainer from './ReportContainer';

type LastSave = {
  lancado: boolean;
  data_hora: string | null;
};

function MidContainer({
  folhaDecisaoData,
  dataEtapa,
  gameDetailsDTO,
  scale = 1,
  lastSave,
  boxStyle,
  onChange,
}: Readonly<{
  folhaDecisaoData: FolhaDecisaoDTO;
  dataEtapa: EtapaDTO;
  gameDetailsDTO: GameDetailsDTO;
  scale?: number;
  lastSave: LastSave;
  boxStyle?: React.CSSProperties;
  onChange: (data: any) => void;
}>) {
  const formatToBrasiliaTime = (dateString: string | null): string => {
    if (dateString) {
      const date = new Date(dateString);

      const options: Intl.DateTimeFormatOptions = {
        timeZone: 'America/Sao_Paulo',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      };

      return `${new Intl.DateTimeFormat('pt-BR', options).format(date).replace(',', '')} BSB`;
    } else {
      return '-';
    }
  };

  return (
    <Box sx={{ height: '80%', alignContent: 'center', ...boxStyle, position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          left: scale === 0.7 ? '75%' : scale < 0.85 ? '65%' : '72%',
          fontSize: scale === 0.7 ? '12px' : scale === 1 ? '15px' : '12.5px',
        }}
      >
        <Box sx={{ width: scale === 0.7 ? '125px' : '100%' }} display='flex' pt={3.5}>
          Último Envio: {lastSave.lancado ? formatToBrasiliaTime(lastSave.data_hora) : '-'}
        </Box>
      </Box>
      <TabTable
        tabBoxStyle={{ position: 'relative', height: '100%' }}
        tabs={[
          {
            tabTitle: 'Tomada de Decisão',
            tabChild: (
              <MidContainerContent
                scale={scale}
                onChange={onChange}
                folhaDecisaoData={folhaDecisaoData}
                dataEtapa={dataEtapa}
                gameDetailsDTO={gameDetailsDTO}
              />
            ),
          },
          {
            tabTitle: 'Meu Estoque',
            tabChild: <MyStockContent scale={scale} folhaDecisaoData={folhaDecisaoData} gameDetailsDTO={gameDetailsDTO} />,
          },
          { tabTitle: 'Relatórios', tabChild: <ReportContainer /> },
        ]}
      />
    </Box>
  );
}

export default MidContainer;
