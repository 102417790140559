import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import UserProfileBox from '../../Moleculas/UserProfileBox';
import NavigationButtons from '../../Moleculas/NavigationButtons';
import RankingBox from '../../Moleculas/RankingBox';
import Button1 from '../../Atomos/Button1';
import { scaleValue } from '../../utils';
const scale = 1;
const scaledBoxStyle = {
    width: scaleValue(scale, 302 - 24 * 2),
    height: scaleValue(scale, 1080 - 38 * 2),
    background: 'white',
    border: '1px #E0E0E0 solid',
    padding: `${scaleValue(scale, 38)}px ${scaleValue(scale, 24)}px`,
};
export default function LeftContainer({ boxStyle }) {
    return (_jsxs(Box, { display: 'flex', flexDirection: 'column', sx: { ...scaledBoxStyle, ...boxStyle }, children: [_jsx(UserProfileBox, { name: 'John Doe', company: 'Empresa X', scenario: 'Cen\u00E1rio X', src: 'https://via.placeholder.com/35x35', scenarioCircleColor: 'green', boxStyle: { marginBottom: scaleValue(scale, '56px'), width: 'auto' } }), _jsxs(Box, { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', children: [_jsxs(Box, { children: [_jsx(Typography, { sx: {
                                    color: 'black',
                                    fontSize: 16,
                                    fontFamily: 'Poppins',
                                    fontWeight: '700',
                                    letterSpacing: 0.15,
                                    wordWrap: 'break-word',
                                }, children: "Navegue" }), _jsx(NavigationButtons, { onChange: () => null, boxStyle: { marginBottom: scaleValue(scale, '12px') } }), _jsx(RankingBox, { subtitle: 'Ranking de usu\u00E1rios', names: ['Concorrente 1', 'Concorrente 2', 'Concorrente 3'] })] }), _jsx(Button1, { text: 'Voltar', icon: _jsx(LogoutOutlinedIcon, { sx: { fontSize: scaleValue(scale, 20) } }), isActive: true })] })] }));
}
