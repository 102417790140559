import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, ListItem } from "@mui/material";
export default function ProductFieldError({ section, type, field, }) {
    return (_jsxs(ListItem, { sx: {
            bgcolor: '#f4f4f4',
            borderRadius: '15px',
            marginBottom: '10px',
            minWidth: '150px',
        }, children: [_jsx(Box, { sx: {
                    flexGrow: 1,
                    flexBasis: '20%',
                    textAlign: 'start',
                }, children: _jsxs("strong", { children: [" ", type] }) }), _jsx(Box, { sx: {
                    flexGrow: 1,
                    flexBasis: '40%',
                    textAlign: 'center',
                }, children: _jsxs("strong", { children: [" ", section] }) }), _jsxs(Box, { sx: {
                    flexGrow: 1,
                    flexBasis: '40%',
                    textAlign: 'center',
                }, children: ["Campo:", _jsxs("strong", { children: [" ", field] })] })] }));
}
