import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { IconCard } from '../../Atomos/IconCard';
import { ReactComponent as RhIcon } from './RhIcon.svg';
import { ReactComponent as ColumnAverage } from './ColumnAverage.svg';
import { ReactComponent as InvoiceTip } from './InvoiceTip.svg';
import { ReactComponent as Sign } from './Sign.svg';
import { ReactComponent as StackedBarUp } from './StackedBarUp.svg';
const ICONS = ['RhIcon', 'ColumnAverage', 'InvoiceTip', 'Sign', 'StackedBarUp'];
const iconMap = {
    RhIcon,
    ColumnAverage,
    InvoiceTip,
    Sign,
    StackedBarUp,
};
export default function IconCardList({ icons, onChange, initialSelected, hasError }) {
    const [isSelected, setIsSelected] = React.useState(initialSelected);
    const handleIconClick = (icon) => {
        setIsSelected(icon.name);
        onChange(icon);
    };
    return (_jsx("div", { style: { display: 'flex', gap: '28px' }, children: icons.map((icon, index) => {
            const IconComponent = iconMap[icon.name];
            return (_jsx(IconCard, { label: icon.label, isSelected: icon.name === isSelected, hasError: hasError(icon), icon: _jsx(IconComponent, {}), onClick: () => handleIconClick(icon) }, index));
        }) }));
}
