import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
const checkboxCss = {
    width: '26px',
    height: '26px',
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
    background: '#FFF',
    color: 'transparent',
    marginRight: '8px',
};
const textCss = {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '0.15px',
};
export const CheckboxWithLabel = ({ label, checked, onChange, checkboxStyle, textStyle }) => {
    const [isChecked, setIsChecked] = React.useState(checked);
    const handleChange = (event) => {
        setIsChecked(event.target.checked);
        onChange(event);
    };
    return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: isChecked, onChange: handleChange, sx: { ...checkboxCss, ...checkboxStyle, '&.Mui-checked': { color: 'gray' } } }), label: _jsx(Typography, { variant: 'body1', sx: { ...textCss, ...textStyle }, children: label }), sx: { margin: '0px' } }));
};
