import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const boxStyle = {
    width: '42px',
    height: '42px',
    backgroundColor: '#00FF72',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
};
// Função auxiliar para rodar o ícone dependendo da direção
const getRotation = (direction) => {
    switch (direction) {
        case 'up':
            return '270deg';
        case 'down':
            return '90deg';
        case 'left':
            return '180deg';
        case 'right':
        default:
            return '0deg';
    }
};
const ArrowButton = ({ direction, onClick, buttonStyle }) => {
    return (_jsx(Box, { onClick: onClick, sx: { ...boxStyle, ...buttonStyle }, children: _jsx(ArrowForwardIosIcon, { sx: {
                transform: `rotate(${getRotation(direction)})`,
                color: 'black', // Cor da seta
            } }) }));
};
export default ArrowButton;
