import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Typography, Box } from '@mui/material';
import Box1 from '../../Atomos/Box';
import { ReactComponent as TrophyIcon } from './TrophyIcon.svg';
function RankingTiles({ name, position }) {
    return (_jsx("div", { style: {
            background: 'linear-gradient(0deg, #F3F3F3 0%, #F3F3F3 100%), #FFF',
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 9,
            border: '1px #E0E0E0 solid',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'inline-flex',
        }, children: _jsxs("div", { style: {
                textAlign: 'center',
                color: '#666666',
                fontSize: 12,
                fontFamily: 'Poppins',
                fontWeight: '400',
                wordWrap: 'break-word',
                display: 'flex',
                gap: 14,
                alignItems: 'flex-end',
                justifyContent: 'space-evenly',
                width: '100%',
            }, children: [_jsxs("span", { style: { fontWeight: 'bold', fontSize: 15 }, children: [position, "."] }), _jsx("span", { children: name })] }) }));
}
export default function RankingBox({ subtitle, names }) {
    return (_jsxs(Box1, { display: 'flex', flexDirection: 'column', sx: { padding: '6px 6px 10px 6px', alignItems: 'center', justifyContent: 'center', height: 'auto', width: 'auto' }, children: [_jsxs(Box, { display: 'flex', flexDirection: 'column', alignItems: 'center', children: [_jsx(Typography, { style: { color: 'black', fontSize: 18, fontFamily: 'Poppins', fontWeight: '600', wordWrap: 'break-word' }, children: "Ranking" }), _jsx(Typography, { style: { color: 'black', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word' }, children: subtitle })] }), _jsx(Box, { display: 'flex', flexDirection: 'column', gap: '8px', children: names.map((name, index) => (_jsx(RankingTiles, { name: name, position: index + 1 }, index))) }), _jsx(TrophyIcon, {})] }));
}
