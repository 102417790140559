import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box1 from '../../Atomos/Box';
import InputWithTitle2 from '../../Moleculas/InputWithTitle2';
import { scaleValue } from '../../utils'; // Função scaleValue
export default function InvoiceContainer({ rowOne, rowTwo, rowThree, rowFour, onChange, scale = 1 }) {
    const values = {};
    const handleChangeInput = (key, value, error) => {
        values[key] = value;
        onChange(values, error);
    };
    return (_jsxs(Box1, { display: 'flex', flexDirection: 'column', sx: {
            height: 'auto',
            padding: `${scaleValue(scale, '23px')} ${scaleValue(scale, '40px')}`,
            minWidth: scaleValue(scale, '829px'),
            gap: scaleValue(scale, '28px'),
            width: '95%',
            boxSizing: 'border-box',
        }, children: [_jsx(InputWithTitle2, { title: 'Desconto de T\u00EDtulos($)', tooltipText: 'Valor do contas a receber na etapa X+1 que poder\u00E1 ser descontado. O banco repassa o valor, descontado dos juros.', initialAdornment: '$', disabled: rowOne.valueInputOne.disabled, validator: rowOne.valueInputOne.validator, helperText: rowOne.valueInputOne.helperText, onChange: (event, error) => handleChangeInput(rowOne.valueInputOne.key, event.target.value, error), defaultValue: rowOne.valueInputOne.value, scale: scale }), _jsx(InputWithTitle2, { title: 'Antecipa\u00E7\u00E3o de Pagamentos($)', tooltipText: 'Possibilidade de antecipar pagamentos dos fornecedores na etapa X+1.', initialAdornment: '$', disabled: rowTwo.valueInputOne.disabled, validator: rowTwo.valueInputOne.validator, helperText: rowTwo.valueInputOne.helperText, onChange: (event, error) => handleChangeInput(rowTwo.valueInputOne.key, event.target.value, error), defaultValue: rowTwo.valueInputOne.value, scale: scale }), _jsx(InputWithTitle2, { title: 'Amplia\u00E7\u00E3o M2', tooltipText: 'Aumento, em m2, das instala\u00E7\u00F5es f\u00EDsicas da empresa.', disabled: rowThree.valueInputOne.disabled, validator: rowThree.valueInputOne.validator, helperText: rowThree.valueInputOne.helperText, onChange: (event, error) => handleChangeInput(rowThree.valueInputOne.key, event.target.value, error), defaultValue: rowThree.valueInputOne.value, scale: scale }), _jsx(InputWithTitle2, { title: 'Diversos', tooltipText: 'Valor disponibilizado pelo sistema. Utilizado para inser\u00E7\u00E3o de b\u00F4nus ou multas.', initialAdornment: '$', disabled: (rowFour?.valueInputOne).disabled, validator: (rowFour?.valueInputOne).validator, helperText: (rowFour?.valueInputOne).helperText, onChange: (event, error) => handleChangeInput((rowFour?.valueInputOne).key, event.target.value, error), defaultValue: (rowFour?.valueInputOne).value, scale: scale })] }));
}
