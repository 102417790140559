import { Box, Grid, Typography } from '@mui/material';
import { OutlinedCardWhitValues } from '@repo/ui';
import { IndicatorsDTO } from '../../../dto';

export type FinesSectionProps = {
  dataIndicator: IndicatorsDTO | undefined;
};

export default function FinesSection({ dataIndicator }: FinesSectionProps): JSX.Element {
  return (
    <Box width='85%' mt={{ xs: 6, sm: 6, md: 4, lg: 4 }}>
      <Typography color='#00AB4A' fontSize='clamp(1.5rem, 2.5vw, 1.8rem)' fontFamily='Poppins' fontWeight={700}>
        Juros/Multa
      </Typography>
      <Grid container spacing={2} pt={3}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <OutlinedCardWhitValues
            title='Juros'
            values={
              dataIndicator
                ? Object.entries(dataIndicator)
                    .filter(([key]) => key.startsWith('juro'))
                    .reduce((acc, [key, value]) => {
                      acc[key] = value;
                      return acc;
                    }, {} as Record<string, unknown>)
                : {}
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <OutlinedCardWhitValues
            title='Multas'
            values={
              dataIndicator
                ? Object.entries(dataIndicator)
                    .filter(([key]) => key.startsWith('multa'))
                    .reduce((acc, [key, value]) => {
                      acc[key] = value;
                      return acc;
                    }, {} as Record<string, unknown>)
                : {}
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}
