import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, DialogActions, Button, DialogContentText, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function AlertRefreshModal({ modalIsOpen, onClose, onConfirm, }) {
    return (_jsxs(Dialog, { open: modalIsOpen, onClose: onClose, PaperProps: {
            sx: {
                overflow: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "475px",
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                padding: "0rem 1.5rem",
                boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
            },
        }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", children: [_jsx(DialogTitle, { sx: { flex: 1, textAlign: "center", padding: "25px 0px 0px 0px" }, children: _jsx(Typography, { variant: "h6", component: "div", sx: { fontWeight: "bold" }, children: "Aten\u00E7\u00E3o!" }) }), _jsx(IconButton, { onClick: onClose, sx: { position: "absolute", top: 8, right: 8, color: "#000000" }, children: _jsx(CloseIcon, {}) })] }), _jsxs(DialogContent, { sx: { textAlign: "center" }, children: [_jsx(DialogContentText, { sx: { padding: 0, fontSize: "17px" }, children: "Voc\u00EA tem altera\u00E7\u00F5es n\u00E3o salvas. Se voc\u00EA continuar, as altera\u00E7\u00F5es n\u00E3o ser\u00E3o salvas e a \u00FAltima vers\u00E3o salva da Tomada de Decis\u00E3o ser\u00E1 recuperada. Deseja continuar?" }), _jsxs(DialogActions, { sx: { padding: "25px 0px 0px 0px" }, children: [_jsx(Button, { sx: {
                                    color: "black",
                                    textTransform: "none",
                                }, onClick: onClose, children: "Cancelar" }), _jsx(Button, { sx: {
                                    backgroundColor: "#0077B6",
                                    color: "white",
                                    textTransform: "none",
                                    width: "125px",
                                    "&:hover": {
                                        backgroundColor: "#004A6A", // Tom mais claro no hover
                                    },
                                }, onClick: onConfirm, children: "Sim, atualizar" })] })] })] }));
}
