import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import Box1 from '../Box';
import { scaleValue } from '../../utils'; // Função scaleValue
const titleStyle = {
    color: 'black',
    fontSize: '15px',
    fontFamily: 'Poppins',
    fontWeight: '700',
    letterSpacing: 0.15,
    wordWrap: 'break-word',
};
const subtitleStyle = {
    color: '#00AB4A',
    fontSize: '23px',
    fontFamily: 'Poppins',
    fontWeight: '700',
    letterSpacing: 0.15,
    wordWrap: 'break-word',
};
export default function MoneyBox({ color, subtitle, title, scale, boxStyle, }) {
    scale = scale ?? 1;
    return (_jsxs(Box1, { display: 'flex', flexDirection: 'column', sx: {
            padding: scaleValue(scale, '18px 32px', 0.2),
            width: scaleValue(scale, '264px', 0.2),
            height: scaleValue(scale, '100px', 0.2),
            ...boxStyle,
        }, children: [_jsx(Typography, { sx: {
                    ...titleStyle,
                    fontSize: scaleValue(scale, titleStyle.fontSize, 0.3), // Escalando a fonte
                }, children: title }), _jsx(Typography, { sx: {
                    ...subtitleStyle,
                    fontSize: scaleValue(scale, subtitleStyle.fontSize, 0.3),
                    color: color ?? '#00AB4A',
                }, children: subtitle })] }));
}
