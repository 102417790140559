import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 3px',
    borderRadius: '8px',
    border: `2px solid #E0E0E0`,
    width: '100px',
    height: '85px',
    transition: 'border-color 0.3s',
    cursor: 'pointer',
    fontWeight: 'bold',
};
const textCss = {
    overflowWrap: 'anywhere',
    textAlign: 'center',
};
export const IconCard = ({ icon, label, isSelected, hasError, onClick }) => {
    return (_jsxs(Box, { sx: { ...boxStyle, borderColor: hasError ? 'red' : isSelected ? '#07FD72' : '#E0E0E0' }, onClick: onClick, children: [_jsx(Box, { sx: { mb: 1 }, children: icon }), _jsx(Typography, { variant: 'body2', sx: { ...textCss }, children: label })] }));
};
