import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Box1 from '../../Atomos/Box';
import InputWithTitle from '../../Moleculas/InputWithTitle';
import SelectWithTitle from '../../Moleculas/SelectWithTitle';
import { CheckboxWithLabel } from '../../Atomos/CheckBoxWithLabel';
import { HelpTooltip } from '../../Atomos/HelpTooltip';
import { scaleValue } from '../../utils'; // Função scaleValue
export default function ProductContainer({ productName, subProductName, rowOne, rowTwo, rowThree, checkboxStatus, onChange, isTimeOver, scale, }) {
    scale = scale ?? 1;
    const values = {};
    const valueSelectOne = rowOne.valueInputOne[0].defaultValue;
    const valueSelectTwo = rowTwo.valueInputOne[0].defaultValue;
    const [isBuyBloqued, setIsBuyBloqued] = React.useState(false);
    const [isSellBloqued, setIsSellBloqued] = React.useState(false);
    const handleChangeInput = (key, value, error) => {
        values[key] = value;
        onChange(values, error);
    };
    const handleChangeSelect = (option) => {
        values[option.key ?? option.label] = option.value;
        onChange(values, null);
    };
    useEffect(() => {
        if (Number(valueSelectOne?.value) === 0) {
            setIsBuyBloqued(true);
        }
        else {
            if (isBuyBloqued)
                setIsBuyBloqued(false);
        }
        if (Number(valueSelectTwo?.value) === 0) {
            setIsSellBloqued(true);
        }
        else {
            if (isSellBloqued)
                setIsSellBloqued(false);
        }
    }, [isBuyBloqued, isSellBloqued, rowOne, rowTwo, valueSelectOne?.value, valueSelectTwo?.value]);
    return (_jsxs(Box1, { display: 'flex', flexDirection: 'column', sx: {
            height: 'auto',
            padding: scaleValue(scale, '23px 40px'),
            width: '95%',
            boxSizing: 'border-box',
        }, children: [_jsxs(Box, { children: [_jsx(Typography, { sx: {
                            width: scaleValue(scale, '100%'),
                            color: 'black',
                            fontSize: scaleValue(scale, 36),
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                        }, children: productName }), _jsx(Typography, { sx: {
                            width: scaleValue(scale, '100%'),
                            color: 'rgba(0, 0, 0, 0.60)',
                            fontSize: scaleValue(scale, 12),
                            fontFamily: 'Poppins',
                            fontWeight: '400',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                        }, children: subProductName })] }), _jsxs(Box, { display: 'flex', flexDirection: 'column', width: '100%', gap: scaleValue(scale, '32px'), marginTop: scaleValue(scale, '12px'), children: [_jsxs(Box, { display: 'flex', flexDirection: 'column', sx: { width: '100%' }, children: [_jsx(Typography, { sx: {
                                    width: scaleValue(scale, '100%'),
                                    color: 'black',
                                    fontSize: scaleValue(scale, 22),
                                    fontFamily: 'Poppins',
                                    fontWeight: '700',
                                    letterSpacing: 0.15,
                                    wordWrap: 'break-word',
                                }, children: "Compras" }), _jsxs(Box, { display: 'flex', sx: { justifyContent: 'space-between', gap: '4px', maxWidth: '998px', flexFlow: 'wrap' }, children: [_jsx(SelectWithTitle, { title: 'Prazo de Pagamento', tooltipText: 'Selecione a op\u00E7\u00E3o de compra.', option: rowOne.valueInputOne[0].defaultValue, options: rowOne.valueInputOne, onSelect: handleChangeSelect, disabled: isTimeOver, divStyle: { width: '33%' } }), _jsx(InputWithTitle, { title: 'Pre\u00E7o de Custo ($)', validator: rowOne.valueInputTwo.validator, helperText: rowOne.valueInputTwo.helperText, disabled: rowOne.valueInputTwo.disabled, tooltipText: 'Valor disponibilizada automaticamente pelo sistema.', initialAdornment: '$', onChange: (event, error) => handleChangeInput(rowOne.valueInputTwo.key, event.target.value, error), defaultValue: rowOne.valueInputTwo.value }), _jsx(InputWithTitle, { disabled: isBuyBloqued || rowOne.valueInputThree.disabled, onlyPositiveNumber: true, onlyInteger: true, title: 'Qtd. de Compra', validator: rowOne.valueInputThree.validator, helperText: rowOne.valueInputThree.helperText, tooltipText: 'Insira a quantidade a ser comprada do produto \u2013 m\u00E1ximo de 100% sobre a quantidade prevista no jornal.', onChange: (event, error) => handleChangeInput(rowOne.valueInputThree.key, event.target.value, error), defaultValue: rowOne.valueInputThree.value })] })] }), _jsxs(Box, { display: 'flex', flexDirection: 'column', sx: { width: '100%' }, children: [_jsx(Typography, { sx: {
                                    width: scaleValue(scale, '100%'),
                                    color: 'black',
                                    fontSize: scaleValue(scale, 22),
                                    fontFamily: 'Poppins',
                                    fontWeight: '700',
                                    letterSpacing: 0.15,
                                    wordWrap: 'break-word',
                                }, children: "Venda" }), _jsxs(Box, { display: 'flex', gap: scaleValue(scale, '4px'), sx: { margin: scaleValue(scale, '12px 0px') }, children: [_jsx(CheckboxWithLabel, { label: 'Com Entradas', checked: checkboxStatus, onChange: (event) => {
                                            const productKey = productName.split(' ').reverse()[0];
                                            const key = `vendas.${productKey}.com_entrada`;
                                            values[key] = event.target.checked;
                                            onChange(values, null);
                                        }, checkboxStyle: { backgroundColor: '#EEE' }, textStyle: {
                                            color: 'rgba(0, 0, 0, 0.50)',
                                            fontSize: scaleValue(scale, '15px'),
                                            fontFamily: 'Poppins',
                                            fontWeight: '500',
                                            letterSpacing: 0.15,
                                            wordWrap: 'break-word',
                                        } }), _jsx(HelpTooltip, { tooltipText: 'Selecione a op\u00E7\u00E3o de venda (com ou sem entrada).', size: 12 })] }), _jsxs(Box, { display: 'flex', sx: { justifyContent: 'space-between', gap: '4px', maxWidth: '998px', flexFlow: 'wrap' }, children: [_jsx(SelectWithTitle, { title: 'Condi\u00E7\u00E3o de Recebimento', tooltipText: 'Selecione a condi\u00E7\u00E3o de recebimento principal pela venda dos produtos.', option: rowTwo.valueInputOne[0].defaultValue, options: rowTwo.valueInputOne, onSelect: handleChangeSelect, disabled: isTimeOver, divStyle: { width: '33%' } }), _jsx(InputWithTitle, { disabled: isSellBloqued || rowTwo.valueInputTwo.disabled, title: 'Pre\u00E7o de Venda ($)', validator: rowTwo.valueInputTwo.validator, helperText: rowTwo.valueInputTwo.helperText, tooltipText: 'Insira o pre\u00E7o de venda do produto.', initialAdornment: '$', onChange: (event, error) => handleChangeInput(rowTwo.valueInputTwo.key, event.target.value, error), defaultValue: rowTwo.valueInputTwo.value }), _jsx(InputWithTitle, { disabled: isSellBloqued || rowTwo.valueInputThree.disabled, title: 'Taxa de Financiamento (%)', validator: rowTwo.valueInputThree.validator, helperText: rowTwo.valueInputThree.helperText, tooltipText: 'Insira uma taxa de financiamento para vendas \u00E0 prazo.', onChange: (event, error) => handleChangeInput(rowTwo.valueInputThree.key, event.target.value, error), defaultValue: rowTwo.valueInputThree.value })] })] }), _jsxs(Box, { display: 'flex', flexDirection: 'column', sx: { width: '100%' }, children: [_jsx(Typography, { sx: {
                                    width: scaleValue(scale, '100%'),
                                    color: 'black',
                                    fontSize: scaleValue(scale, 22),
                                    fontFamily: 'Poppins',
                                    fontWeight: '700',
                                    letterSpacing: 0.15,
                                    wordWrap: 'break-word',
                                }, children: "Marketing" }), _jsxs(Box, { display: 'flex', sx: { justifyContent: 'space-between', gap: '4px', maxWidth: '998px', flexFlow: 'wrap' }, children: [_jsx(SelectWithTitle, { divStyle: { width: '21%' }, title: 'Impresso', tooltipText: 'Insira aqui o n\u00FAmero de campanhas de impresso que voc\u00EA deseja investir para divulgar o produto.', option: rowThree.valueInputOne[0].defaultValue, options: rowThree.valueInputOne, disabled: isTimeOver, onSelect: handleChangeSelect }), _jsx(SelectWithTitle, { divStyle: { width: '21%' }, title: 'M\u00EDdias Sociais', tooltipText: 'Insira aqui o n\u00FAmero de campanhas de m\u00EDdias sociais que voc\u00EA deseja investir para divulgar o produto.', option: rowThree.valueInputTwo[0].defaultValue, options: rowThree.valueInputTwo, disabled: isTimeOver, onSelect: handleChangeSelect }), _jsx(SelectWithTitle, { divStyle: { width: '21%' }, title: 'R\u00E1dio', tooltipText: 'Insira aqui o n\u00FAmero de campanhas de r\u00E1dio que voc\u00EA deseja investir para divulgar o produto.', option: rowThree.valueInputThree[0].defaultValue, options: rowThree.valueInputThree, disabled: isTimeOver, onSelect: handleChangeSelect }), _jsx(SelectWithTitle, { divStyle: { width: '21%' }, title: 'Tv', tooltipText: 'Insira aqui o n\u00FAmero de campanhas de tv que voc\u00EA deseja investir para divulgar o produto.', option: rowThree.valueInputFour[0].defaultValue, options: rowThree.valueInputFour, disabled: isTimeOver, onSelect: handleChangeSelect })] })] })] })] }));
}
