import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-lonely-if */
import { Box, InputAdornment, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Input1 from '../../Atomos/Input1';
import { HelpTooltip } from '../../Atomos/HelpTooltip';
import { Formarters } from '../../utils';
const textStyle = {
    color: 'rgba(0, 0, 0, 0.60)',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px',
    letterSpacing: '0.15px',
};
export default function InputWithTitle({ title, tooltipText, initialAdornment, defaultValue, inputStyle, disabled, onChange, validator, onlyInteger, helperText, onlyPositiveNumber = true, }) {
    const [error, setError] = useState(null); // Estado para o erro
    const [actualValue, setActualValue] = useState(); // Estado para o valor atual
    const [lastestValue, setLastestValue] = useState();
    const [wasEdited, setWasEdited] = useState(false);
    useEffect(() => {
        const isError = validator?.(actualValue || defaultValue || '');
        if (isError)
            setError(isError);
        else if (error !== null)
            setError(null);
    }, []);
    useEffect(() => {
        if (disabled)
            setActualValue(undefined);
        else
            setActualValue(defaultValue);
        const isError = validator?.(defaultValue || '');
        if (isError)
            setError(isError);
        else if (error !== null)
            setError(null);
    }, [disabled]);
    const handleInputChange = (event) => {
        let value = event.target.value.replace('.', ',');
        let isError = false;
        let validationError = null;
        const normalizedValue = Formarters.parseNumberWithComma(value);
        // Executa a função de validação, se houver
        if (validator) {
            validationError = validator(value);
            setError(validationError); // Atualiza o estado do erro
        }
        if (onlyInteger && value !== '') {
            if (!normalizedValue && normalizedValue !== 0) {
                isError = true;
            }
            if (!isError) {
                value = parseInt(value).toString();
            }
        }
        if (onlyPositiveNumber) {
            let canUpdateValue = true;
            if (normalizedValue < 0)
                canUpdateValue = false;
            if (isError)
                canUpdateValue = false;
            if (value === 'NaN')
                canUpdateValue = false;
            if (canUpdateValue || value === '') {
                if (onlyInteger)
                    setActualValue(value);
                else
                    setActualValue(Formarters.maskMoneyUnlimited(value));
                onChange?.(event, validationError);
                if (wasEdited === false) {
                    setWasEdited(true);
                }
            }
        }
        else {
            if (onlyInteger)
                setActualValue(value);
            else
                setActualValue(Formarters.maskMoneyUnlimited(value));
            onChange?.(event, validationError);
            if (wasEdited === false) {
                setWasEdited(true);
            }
        }
    };
    return (_jsxs("div", { children: [_jsxs(Box, { display: 'flex', gap: '4px', children: [_jsx(Typography, { style: { ...textStyle }, children: title }), _jsx(HelpTooltip, { tooltipText: tooltipText, size: 12 })] }), _jsx(Input1, { sx: { marginTop: '4px', ...inputStyle }, onChange: handleInputChange, defaultValue: defaultValue, value: actualValue, disabled: disabled, error: !!error, helperText: error || helperText, isControlledComponent: true, InputProps: {
                    startAdornment: _jsx(InputAdornment, { position: 'start', children: initialAdornment }),
                }, onFocus: () => {
                    setLastestValue(actualValue);
                    setActualValue(''); // Limpa o valor ao focar
                }, onBlur: (e) => {
                    const v = e.target.value;
                    if (!v) {
                        if (wasEdited)
                            setActualValue('0');
                        else
                            setActualValue(lastestValue); // Restaura o valor padrão se o campo estiver vazio
                    }
                    setWasEdited(false);
                } })] }));
}
