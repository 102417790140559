import { jsx as _jsx } from "react/jsx-runtime";
const style = {
    width: '100%',
    height: '100%',
    boxShadow: '0px 1px 1.7999999523162842px rgba(0, 0, 0, 0.10)',
    borderRadius: 9999,
    border: '4px #E0E0E0 solid',
};
export default function CircleAvatarImage({ src, width, height }) {
    return _jsx("img", { style: { ...style, width: width ?? '100%', height: height ?? '100%' }, src: src, alt: 'avatar' });
}
