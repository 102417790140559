const cache = {};
export function scaleValue(scale, value, factorProportion = 1) {
    const cacheKey = `${scale}_${value}`;
    const adjustedScale = factorProportion === 1 ? scale : 1 - (1 - scale) * factorProportion;
    // Verifica se o resultado já está em cache
    if (cache[cacheKey]) {
        return cache[cacheKey];
    }
    let result;
    if (typeof value === 'number') {
        result = value * adjustedScale;
    }
    else if (typeof value === 'string' && value.includes('px')) {
        result = `${parseFloat(value) * adjustedScale}px`;
    }
    else {
        result = value;
    }
    // Armazena o resultado no cache para reutilização futura
    cache[cacheKey] = result;
    return result;
}
export class Formarters {
    static formatMoney(value) {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Formarters.parseNumberWithComma(value));
    }
    static parseNumberWithComma(n) {
        if (typeof n === 'string') {
            // Remove todos os separadores de milhares (pontos ou vírgulas)
            // e mantenha a vírgula ou ponto no final para separador decimal
            const cleaned = n.replace(/[^0-9,.]/g, ''); // Remove caracteres não numéricos (exceto ponto e vírgula)
            // Identifica qual é o separador decimal (último ponto ou vírgula)
            const lastCommaIndex = cleaned.lastIndexOf(',');
            const lastDotIndex = cleaned.lastIndexOf('.');
            const decimalSeparatorIndex = Math.max(lastCommaIndex, lastDotIndex);
            if (decimalSeparatorIndex !== -1) {
                // Divide a string em parte inteira e parte decimal
                const integerPart = cleaned.substring(0, decimalSeparatorIndex).replace(/[,.]/g, '');
                const decimalPart = cleaned.substring(decimalSeparatorIndex + 1);
                return Number(`${integerPart}.${decimalPart}`);
            }
            // Se não houver separador decimal, converte normalmente
            return Number(cleaned.replace(/[,.]/g, ''));
        }
        return Number(n);
    }
    static maskMoneyUnlimited(value) {
        // Remove todos os caracteres que não são dígitos
        value = value.replace(/\D/g, '');
        // Se o valor for menor que 3 dígitos, ajuste para ter pelo menos 3
        // while (value.length < 3) {
        //   value = `0${value}`;
        // }
        // Adiciona a vírgula para os decimais
        value = value.replace(/(\d{2})$/, ',$1');
        // Adiciona o ponto para cada grupo de milhares
        value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        // Adiciona o símbolo de moeda
        return value;
    }
}
