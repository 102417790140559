import { Box, Button, Typography, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { NewsHeader } from '@repo/ui';
import { useIndicadorResults } from '../../api/indicador';
import ProductSection from './Product';
import SalariesSection from './Salaries';
import BuildingCostSection from './BuildingCost';
import MarketingSection from './Marketing';
import FinesSection from './FinesSection';

const Indicators: React.FC = () => {
  const jogoId = localStorage.getItem('jogo_id') ?? '';
  const numeroEtapa = localStorage.getItem('numero_etapa') ?? '';
  const game = localStorage.getItem('nome_jogo') ?? '';

  const { data, isLoading, error } = useIndicadorResults(jogoId, parseInt(numeroEtapa));

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  url.pathname = '/mainpage';

  useEffect(() => {
    document.title = 'xSkill Simulador - Indicadores';
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress color='success' size={80} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' pb={4}>
        <NewsHeader hasSelect={false} gameEtapa={numeroEtapa} gameName={game} />
        <Typography
          sx={{ padding: '15px', textAlign: 'center' }}
          color='#6666'
          fontSize='clamp(1rem, 2.5vw, 1.5rem)'
          fontFamily='Poppins'
          fontWeight={400}
          lineHeight='43px'
        >
          Não foi possível acessar os indicadores, tente novamente.
        </Typography>
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' pb={4}>
      {/* Header */}
      <NewsHeader hasSelect={false} gameEtapa={numeroEtapa} gameName={game} />

      {/* Título com botão de voltar */}
      <Box mt={{ xs: 6, sm: 6, md: 4, lg: 4 }} sx={{ width: '85%', position: 'relative' }}>
        <Box
          mt={{ xs: 5, sm: 5, md: 0, lg: 0 }}
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography color='#00AB4A' fontSize='clamp(2rem, 2.5vw, 2.5rem)' fontFamily='Poppins' fontWeight={700} lineHeight='43px'>
            Indicadores da Etapa {numeroEtapa}
          </Typography>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: { xs: '0%', sm: '0%', md: '50%', lg: '50%' },
            transform: 'translateY(-50%)',
          }}
        >
          <Button
            onClick={() => window.open(url.toString(), '_self')}
            sx={{
              backgroundColor: '#00AB4A',
              borderRadius: '15px',
              fontFamily: 'Poppins',
              fontSize: '15px',
              fontWeight: 600,
              width: '150px',
              color: '#fff',
              padding: '8px 0px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#007B36', // Ajuste de cor no hover
              },
            }}
            variant='contained'
          >
            Voltar
          </Button>
        </Box>
      </Box>
      {/* Seção de Produtos */}
      <ProductSection dataIndicator={data} />
      {/* Seção de  Juros/Multas */}
      <FinesSection dataIndicator={data} />
      {/* Seção de  Custo das campanhas de marketing */}
      <MarketingSection dataIndicator={data} />
      {/* Seção de  Piso salarial */}
      <SalariesSection dataIndicator={data} />
      {/* Seção de Custos por M2 */}
      <BuildingCostSection dataIndicator={data} />
    </Box>
  );
};

export default Indicators;
