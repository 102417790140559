import { Box, Button, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { DropdownSelect } from '@repo/ui';

export type DropdownSelectOption = {
  label: string;
  value: string;
  key?: string;
  defaultValue?: DropdownSelectOption;
};

export default function ReportContainer(): JSX.Element {
  const etapaAtual = localStorage.getItem('numero_etapa') ?? '0';
  const resultadosLiberados = localStorage.getItem('resultados_liberados') ?? '';

  const [dropdownValues, setDropdownValues] = useState<{
    empresa: DropdownSelectOption;
    mercado: DropdownSelectOption;
  }>({
    empresa: { label: '', value: '' },
    mercado: { label: '', value: '' },
  });

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  const [options, setOptions] = useState<DropdownSelectOption[]>([]);

  const handleChangeSelect = (option: DropdownSelectOption, type: 'empresa' | 'mercado') => {
    setDropdownValues((prevState) => ({
      ...prevState,
      [type]: option,
    }));
  };

  const openReportOnPage = async (type: string, etapa: string) => {
    url.pathname = `/report/${type}/${etapa}`;
    window.open(url);
  };

  const openReportInitalDataPage = async () => {
    url.pathname = `/report/initialData`;
    window.open(url);
  };

  useEffect(() => {
    let parsedEtapaAtual = parseInt(etapaAtual);
    if (resultadosLiberados === 'false') {
      parsedEtapaAtual -= 1;
    }

    const newOptions: DropdownSelectOption[] = [];

    for (let i = 1; i <= parsedEtapaAtual; i++) {
      newOptions.push({ label: `Etapa ${i}`, value: i.toString() });
    }

    setOptions(newOptions);

    if (newOptions.length > 0) {
      setDropdownValues({
        empresa: newOptions[newOptions.length - 1],
        mercado: newOptions[newOptions.length - 1],
      });
    }
  }, [etapaAtual]);

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Box display='flex' height='50vh' width='100%' justifyContent='center' gap={2}>
        <Box
          gap={2}
          sx={{
            border: '1px solid #E0E0E0',
            borderRadius: '9px',
            width: '40%',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingY: '25px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #E0E0E0',
              borderRadius: '9px',
              width: '60%',
              height: '44px',
            }}
          >
            <Typography fontWeight={700} fontFamily='Poppins'>
              Relatório de Empresa
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '90%',
            }}
          >
            <DropdownSelect
              boxStyle={{ width: '100%' }}
              label=''
              options={options}
              dropdownValue={dropdownValues.empresa}
              onSelect={(option) => handleChangeSelect(option, 'empresa')}
            />
          </Box>
          <Button
            onClick={() => openReportOnPage('company', dropdownValues.empresa.value)}
            sx={{
              backgroundColor: '#00AB4A',
              borderRadius: '9px',
              fontFamily: 'Poppins',
              fontSize: '15px',
              fontWeight: 600,
              width: '206px',
              color: '#fff',
              padding: '8px 0px',
              textTransform: 'none',
              border: '1px solid #007B36',
              '&:hover': {
                backgroundColor: '#007B36', // Ajuste de cor no hover
              },
            }}
            variant='contained'
          >
            Acessar Relatório
          </Button>
        </Box>
        <Box
          gap={2}
          sx={{
            border: '1px solid #E0E0E0',
            borderRadius: '9px',
            width: '40%',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingY: '25px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #E0E0E0',
              borderRadius: '9px',
              width: '60%',
              height: '44px',
            }}
          >
            <Typography fontWeight={700} fontFamily='Poppins'>
              Relatório de Mercado
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '90%',
            }}
          >
            <DropdownSelect
              boxStyle={{ width: '100%' }}
              label=''
              options={options}
              dropdownValue={dropdownValues.mercado}
              onSelect={(option) => handleChangeSelect(option, 'mercado')}
            />
          </Box>
          <Button
            onClick={() => openReportOnPage('market', dropdownValues.mercado.value)}
            sx={{
              backgroundColor: '#00AB4A',
              borderRadius: '9px',
              fontFamily: 'Poppins',
              fontSize: '15px',
              fontWeight: 600,
              width: '206px',
              color: '#fff',
              padding: '8px 0px',
              textTransform: 'none',
              border: '1px solid #007B36',
              '&:hover': {
                backgroundColor: '#007B36', // Ajuste de cor no hover
              },
            }}
            variant='contained'
          >
            Acessar Relatório
          </Button>
        </Box>
      </Box>
      <Button
        onClick={() => openReportInitalDataPage()}
        sx={{
          backgroundColor: '#00AB4A',
          borderRadius: '9px',
          fontFamily: 'Poppins',
          fontSize: '15px',
          fontWeight: 600,
          width: '65%',
          color: '#fff',
          padding: '8px 0px',
          textTransform: 'none',
          border: '1px solid #007B36',
          '&:hover': {
            backgroundColor: '#007B36', // Ajuste de cor no hover
          },
        }}
        variant='contained'
      >
        Acessar Documento Dados Iniciais
      </Button>
    </Box>
  );
}
