import { Box, Grid, Typography } from '@mui/material';
import { OutlinedCard } from '@repo/ui';
import { Formarters } from '../../../lib/formaters';
import { IndicatorsDTO } from '../../../dto';

export type SalariesSectionProps = {
  dataIndicator: IndicatorsDTO | undefined;
};

export default function SalariesSection({ dataIndicator }: SalariesSectionProps): JSX.Element {
  return (
    <Box width='85%' mt={{ xs: 6, sm: 6, md: 4, lg: 4 }}>
      <Typography color='#00AB4A' fontSize='clamp(1.5rem, 2.5vw, 1.8rem)' fontFamily='Poppins' fontWeight={700}>
        Piso salarial
      </Typography>
      <Grid container spacing={2} pt={3}>
        {dataIndicator &&
          Object.entries(dataIndicator.piso_salarial).map(([key, value]) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={key}>
              <OutlinedCard
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ padding: '35px 32px', display: 'flex', justifyContent: 'center' }}>
                  <Typography fontSize='clamp(1.25rem, 2.5vw, 1.45rem)' fontFamily='Poppins' fontWeight={400} color='#666666'>
                    O piso salarial para os <span style={{ fontWeight: 900 }}>{Formarters.translateKeyIndicators(key)}</span> será de
                    <span style={{ fontWeight: 900 }}> ${Formarters.formatHelperText(value)}</span>
                  </Typography>
                </Box>
              </OutlinedCard>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
