import { CircularProgress, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useRelatorioResults, useRelatorioInitialData } from '../../api/relatorio';

export function ReportInitialData(): JSX.Element {
  const jogoId = localStorage.getItem('jogo_id') ?? '';
  const { data, isLoading, error } = useRelatorioInitialData(jogoId);

  useEffect(() => {
    document.title = 'xSkill Simulador - Relátorio';
  }, []);

  function renderPdf() {
    return <iframe src={data} style={{ width: '100%', height: '100vh', border: 'none' }} title='Report' />;
  }

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress color='success' size={60} />
      </Box>
    );

  if (error) return <div>Não possível acessar esse relatório. Tente novamente!</div>;

  return data ? renderPdf() : <div>Sem Conteudo.</div>;
}

export function Report(): JSX.Element {
  useEffect(() => {
    document.title = 'xSkill Simulador - Relátorio';
  }, []);
  const jogoId = localStorage.getItem('jogo_id') ?? '';
  const { type, stage } = useParams();
  const reportType = type === 'company' ? 'empresa' : 'mercado';
  const { data, isLoading, error } = useRelatorioResults(jogoId, reportType, stage || '0', false);

  function renderPdf() {
    return <iframe src={data} style={{ width: '100%', height: '100vh', border: 'none' }} title='Report' />;
  }

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress color='success' size={60} />
      </Box>
    );

  if (error) return <div>Não possível acessar esse relatório. Tente novamente!</div>;

  return data ? renderPdf() : <div>Sem Conteudo.</div>;
}
