import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box1 from '../../Atomos/Box';
import CircleAvatarImage from '../../Atomos/CirlceAvatarImage';
import { ReactComponent as UserIcon } from './UserIcon.svg';
const scenarioCircleColorOptions = {
    green: '🟢',
    red: '🔴',
    yellow: '🟡',
    blue: '🔵',
};
export default function UserProfileBox({ width, height, name, company, scenario, src, scenarioCircleColor, boxStyle, }) {
    const MAX_NAME_LENGTH = 16;
    const MAX_COMPANY_LENGTH = 20;
    const MAX_SCENARIO_LENGTH = 15;
    return (_jsxs(Box1, { display: 'flex', flexDirection: 'column', sx: {
            padding: '8px 24px 12px 14px',
            justifyContent: 'flex-start',
            alignItems: 'normal',
            height: height ?? '100px',
            width: width ?? '264px',
            ...boxStyle,
        }, children: [_jsxs(Box, { display: 'flex', width: '100%', justifyContent: 'space-between', children: [_jsxs(Box, { display: 'flex', children: [_jsx("div", { style: { marginRight: '8px' }, children: src ? (_jsx(CircleAvatarImage, { src: src, width: '35px', height: '35px' })) : (_jsx(AccountCircleIcon, { style: { width: '35px', height: '35px' } })) }), _jsx(Typography, { style: {
                                    alignContent: 'center',
                                    color: 'black',
                                    fontSize: 18,
                                    fontFamily: 'Poppins',
                                    fontWeight: '700',
                                    letterSpacing: 0.15,
                                    wordWrap: 'break-word',
                                }, children: name.substring(0, MAX_NAME_LENGTH) })] }), _jsx(UserIcon, { style: { alignSelf: 'center' } })] }), _jsxs(Box, { display: 'flex', children: [_jsxs(Typography, { style: {
                            color: 'black',
                            fontSize: 12,
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                            marginRight: '4px',
                        }, children: ["Empresa:", ' '] }), _jsx(Typography, { style: { color: 'black', fontSize: 12, fontFamily: 'Poppins', fontWeight: '300', letterSpacing: 0.15, wordWrap: 'break-word' }, children: company.substring(0, MAX_COMPANY_LENGTH) })] }), scenario && (_jsxs(Box, { display: 'flex', children: [_jsxs(Typography, { style: {
                            color: 'black',
                            fontSize: 12,
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word',
                            marginRight: '4px',
                        }, children: ["Cen\u00E1rio:", ' '] }), _jsxs(Typography, { style: { color: 'black', fontSize: 12, fontFamily: 'Poppins', fontWeight: '300', letterSpacing: 0.15, wordWrap: 'break-word' }, children: [scenario.substring(0, MAX_SCENARIO_LENGTH), " ", scenarioCircleColorOptions[scenarioCircleColor]] })] }))] }));
}
