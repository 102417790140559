import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Box, Tooltip, IconButton } from '@mui/material';
import { ReactComponent as HelpIcon } from './HelpIcon.svg';
const style = {
    display: 'flex',
    width: '22px',
    height: '22px',
    background: '#000',
    padding: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    cursor: 'pointer',
};
export const HelpTooltip = ({ tooltipText, size }) => {
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    return (_jsx(Tooltip, { open: open, onClose: handleTooltipClose, title: tooltipText, onClick: handleTooltipOpen, placement: 'top', arrow: true, children: _jsx(Box, { display: 'flex', alignItems: 'center', sx: { ...style, width: size || '22px', height: size || '22px' }, children: _jsx(IconButton, { onClick: handleTooltipOpen, sx: { padding: 0, color: 'white', width: size || '22px', height: size || '22px' }, children: _jsx(HelpIcon, { style: { fontSize: size || 22, height: '14px', width: '9px' } }) }) }) }));
};
