import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import { useState } from 'react';
import Button1 from '../../Atomos/Button1';
// No momento MyCompanies deverá sempre ser marcado como o ativo.
export default function NavigationButtons({ onChange, boxStyle }) {
    const [activeButton, setActiveButton] = useState('MyCompanies');
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        onChange(buttonName); // Propagate the change up to the parent component
    };
    return (_jsxs(Box, { display: 'flex', flexDirection: 'column', gap: '12px', sx: { ...boxStyle }, children: [_jsx(Button1, { text: 'Home', icon: _jsx(HomeOutlinedIcon, { sx: { fontSize: '20px' } }), isActive: true, onClick: () => handleButtonClick('MyCompanies') }), _jsx(Button1, { text: 'Manual', icon: _jsx(CollectionsBookmarkOutlinedIcon, { sx: { fontSize: '20px' } }), isActive: false, onClick: () => handleButtonClick('Tutorial') }), _jsx(Button1, { text: 'Indicadores', icon: _jsx(LeaderboardOutlinedIcon, { sx: { fontSize: '20px' } }), isActive: false, onClick: () => handleButtonClick('Indicators') })] }));
}
