import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Ícone de sucesso
import { keyframes } from '@mui/system'; // Animação
// Animação para o ícone de sucesso
const bounceAnimation = keyframes `
  0% { transform: scale(1); }
  25% { transform: scale(1.1); }
  50% { transform: scale(0.9); }
  75% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;
export default function SuccessModal({ modalIsOpen, onClose, successMessage }) {
    return (_jsxs(Dialog, { open: modalIsOpen, onClose: onClose, PaperProps: {
            sx: {
                overflow: 'auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'fit-content',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                padding: '2rem 1.5rem',
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                textAlign: 'center', // Centraliza o conteúdo
            },
        }, BackdropProps: {
            sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fundo escuro com transparência
            },
        }, children: [_jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', children: [_jsxs(DialogTitle, { sx: { flex: 1, textAlign: 'center', padding: 0 }, children: [_jsx(CheckCircleOutlineIcon, { sx: {
                                    fontSize: 60,
                                    color: 'green',
                                    marginBottom: '16px',
                                    animation: `${bounceAnimation} 0.8s ease-in-out`,
                                } }), _jsx(Typography, { variant: 'h6', component: 'div', sx: { fontWeight: 'bold', color: 'green' }, children: "Sucesso!" })] }), _jsx(IconButton, { onClick: onClose, sx: { position: 'absolute', top: 8, right: 8, color: '#000000' }, children: _jsx(CloseIcon, {}) })] }), _jsx(DialogContent, { sx: { textAlign: 'center', paddingTop: '16px' }, children: _jsx(Typography, { variant: 'body1', sx: { marginBottom: '16px', fontSize: '16px', fontWeight: '500' }, children: successMessage }) })] }));
}
