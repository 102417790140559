import { Box, Grid, Typography } from '@mui/material';
import { OutlinedCardWhitValues } from '@repo/ui';
import { IndicatorsDTO } from '../../../dto';

export type ProductsSectionProps = {
  dataIndicator: IndicatorsDTO | undefined;
};

export default function ProductSection({ dataIndicator }: ProductsSectionProps): JSX.Element {
  return (
    <Box width='85%' mt={{ xs: 6, sm: 6, md: 4, lg: 4 }}>
      <Typography color='#00AB4A' fontSize='clamp(1.5rem, 2.5vw, 1.8rem)' fontFamily='Poppins' fontWeight={700}>
        Produtos
      </Typography>
      <Grid container spacing={2} pt={3}>
        {dataIndicator &&
          Object.entries(dataIndicator.produtos).map(([key, produto]) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={key}>
              <OutlinedCardWhitValues title={`Produto ${key}`} values={produto} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
