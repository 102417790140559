import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
import { Tabs } from '../../Moleculas/Tabs';
const TabContext = createContext(undefined);
export function useTabContext() {
    const context = useContext(TabContext);
    if (!context) {
        throw new Error('useTabContext must be used within a TabContext.Provider');
    }
    return context;
}
export const TabTable = ({ tabs, tabBoxStyle }) => {
    const [tabStorage, setTabStorage] = useState({});
    const updateTabStorage = (key, value) => {
        setTabStorage((prev) => ({ ...prev, [key]: value }));
    };
    return (_jsx(TabContext.Provider, { value: { tabStorage, updateTabStorage }, children: _jsx(Tabs, { tabs: tabs, boxStyle: tabBoxStyle }) }));
};
