import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { HelpTooltip } from '../../Atomos/HelpTooltip';
import { DropdownSelect } from '../../Atomos/DropdownSelect';
const textStyle = {
    color: 'rgba(0, 0, 0, 0.60)',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px' /* 186.667% */,
    letterSpacing: '0.15px',
};
export default function SelectWithTitle({ title, tooltipText, divStyle, options, onSelect, option, disabled }) {
    const handleOnSelect = (optionParam) => {
        onSelect(optionParam);
    };
    return (_jsxs("div", { style: divStyle, children: [_jsxs(Box, { display: 'flex', gap: '4px', children: [_jsx(Typography, { style: { ...textStyle }, children: title }), _jsx(HelpTooltip, { tooltipText: tooltipText, size: 12 })] }), _jsx(DropdownSelect, { disabled: disabled, label: 'Prazo de Pagamento', onSelect: handleOnSelect, boxStyle: { height: 'auto', width: 'auto', marginTop: '4px' }, options: options, dropdownValue: option })] }));
}
