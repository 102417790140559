import { Box, Typography } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import React, { useEffect } from 'react';
import { Button1, RankingBox, NavigationButtons, UserProfileBox, AlertModal } from '@repo/ui';
import { scaleValue } from '../../../lib/utils';
import { Ranking, UserDTO } from '../../../dto';
import { GameDetailsDTO } from '../../../dto/gameDetailsDTO';

type LeftContainerProps = {
  sendButtonActive: boolean;
  boxStyle?: React.CSSProperties;
  ranking: Ranking[];
  gameDetails: GameDetailsDTO;
  pdfData: any;
};

const scale = 1;

const scaledBoxStyle: React.CSSProperties = {
  width: scaleValue(scale, 302 - 24 * 2),
  height: scaleValue(scale, 1080 - 38 * 2),
  background: 'white',
  border: '1px #E0E0E0 solid',
  padding: `${scaleValue(scale, 38 - 12)}px ${scaleValue(scale, 24 - 6)}px`,
};

function LeftContainer({ gameDetails, boxStyle, ranking, pdfData, sendButtonActive }: LeftContainerProps): JSX.Element {
  const [userData, setUserData] = React.useState<UserDTO>();
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) setUserData(JSON.parse(user));
  }, [ranking, pdfData]);

  function getNamesFromRanking(): string[] {
    return ranking.map((u) => u.name);
  }

  function handleNavigationChange(value: string) {
    // possiveis valores serão: MyCompanies | Tutorial | Support | InitialData | Indicators
    if (value.toLowerCase() === 'tutorial') window.open(process.env.REACT_APP_TUTORIAL_URL);
    if (value.toLowerCase() === 'initialdata') window.open(pdfData);
    if (value.toLocaleLowerCase() === 'indicators') {
      url.pathname = '/indicators';
      window.open(url.toString(), '_blank');
    }
  }

  const handleConfirmOut = () => {
    localStorage.setItem('ignoreBeforeUnload', 'true');
    sessionStorage.removeItem('midContainerData');
    localStorage.removeItem('id_etapa');
    localStorage.removeItem('jogo_id');
    localStorage.removeItem('id_empresa');
    localStorage.removeItem('id_empresas');
    localStorage.removeItem('numero_etapa');

    if (process.env.REACT_APP_VUE_WEBSITE_URL) window.location.href = process.env.REACT_APP_VUE_WEBSITE_URL;
    else window.history.back();
  };

  const onOut = () => {
    if (sendButtonActive) {
      setIsAlertOpen(true);
    } else {
      handleConfirmOut();
    }
  };

  return (
    <>
      <AlertModal modalIsOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)} onConfirm={() => handleConfirmOut()} />

      <Box display='flex' flexDirection='column' sx={{ ...scaledBoxStyle, ...boxStyle }}>
        <UserProfileBox
          name={userData?.nome || ''}
          company={gameDetails.empresa.empresa}
          scenario={gameDetails.nome_cenario}
          scenarioCircleColor='green'
          boxStyle={{ marginBottom: scaleValue(scale, '42px'), width: 'auto' }}
        />
        <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%' gap='20px'>
          <Box>
            <Typography
              sx={{
                color: 'black',
                fontSize: 16,
                fontFamily: 'Poppins',
                fontWeight: '700',
                letterSpacing: 0.15,
                wordWrap: 'break-word',
              }}
            >
              Navegue
            </Typography>
            <NavigationButtons onChange={handleNavigationChange} boxStyle={{ marginBottom: scaleValue(scale, '24px') }} />
            <RankingBox subtitle='Ranking de usuários' names={getNamesFromRanking()} />
          </Box>
          <Button1
            text='Sair da Simulação'
            icon={<LogoutOutlinedIcon sx={{ fontSize: scaleValue(scale, 20) }} />}
            isActive
            onClick={() => onOut()}
          />
        </Box>
      </Box>
    </>
  );
}

export default React.memo(LeftContainer);
