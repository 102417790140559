import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
const translateKeyIndicators = (key) => {
    const translations = {
        nome: 'Nome',
        custo: 'Custo',
        demanda_etapa_anterior: 'Demanda Anterior',
        demanda_etapa_atual: 'Demanda Atual',
        demanda_etapa_proxima: 'Próxima Demanda',
        tx_financiamento: 'TX. Financiamento',
        juro_aplicacao_1: 'Aplicação 1',
        juro_aplicacao_2: 'Aplicação 2',
        juro_emprestimo_normal: 'Empréstimo normal',
        juro_emprestimo_especial: 'Empréstimo especial',
        juro_antecipacao_pagamento: 'Antecipação de pagamento',
        juro_desconto_duplicatas: 'Desconto de títulos',
        multa_nao_atendimento_porc: 'Multa por não atendimento',
        vendedor: 'vendedores',
        operacional: 'operacionais',
        construcao: 'a construção',
        aluguel: 'o aluguel',
        tv: 'TV',
        impressos: 'Impressos',
        midias_sociais: 'Mídias Sociais',
        radio: 'Rádio',
    };
    return translations[key] || key;
};
const formatHelperText = (value) => {
    return value.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};
const formatValuesIndicators = (key, value) => {
    switch (key) {
        case 'nome':
            return `${value.toUpperCase()}`;
        case 'custo':
            return `$${formatHelperText(value)}`;
        case 'tx_financiamento':
        case 'juro_aplicacao_1':
        case 'juro_aplicacao_2':
        case 'juro_emprestimo_normal':
        case 'juro_emprestimo_especial':
        case 'juro_antecipacao_pagamento':
        case 'juro_desconto_duplicatas':
        case 'multa_nao_atendimento_porc':
            return `${value}%`;
        default:
            return value;
    }
};
export default function ItemWithLabelAndValue({ label, value }) {
    return (_jsxs(Box, { display: 'flex', justifyContent: 'space-between', py: 0.65, minWidth: '350px', maxWidth: '100%', children: [_jsx(Typography, { fontSize: '15px', fontFamily: 'Poppins', fontWeight: 400, color: '#666666', children: translateKeyIndicators(label) }), _jsx(Typography, { fontSize: '15px', fontFamily: 'Poppins', fontWeight: 700, color: '#666666', children: formatValuesIndicators(label, value) })] }));
}
