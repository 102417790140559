import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography } from '@mui/material';
const activestyle = {
    display: 'flex',
    width: 'auto',
    padding: '7px 12px',
    height: '42px',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '9px',
    background: '#F3F3F3',
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px' /* 186.667% */,
    letterSpacing: '0.15px',
    textTransform: 'none',
    justifyContent: 'start',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
};
const deactiveStyle = {
    color: '#666',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px' /* 186.667% */,
    letterSpacing: '0.15px',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
};
const textStyle = {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px' /* 186.667% */,
    letterSpacing: '0.15px',
    textTransform: 'none',
};
export default function Button1({ text, icon, isActive, sx, ...props }) {
    return (_jsxs(Button, { ...props, style: { ...activestyle, ...sx, color: isActive ? '#000' : '#666' }, children: [icon, _jsx(Typography, { sx: isActive ? { ...textStyle } : { ...deactiveStyle }, children: text })] }));
}
