import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReactComponent as Pallet } from './Pallet.svg';
import { scaleValue } from '../../utils'; // Importando a função scaleValue
export default function SatisfationMeter({ percentage, scale = 1 }) {
    if (scale < 0.5)
        scale = 0.5; // Limitando o valor mínimo de scale
    // This is based on the width of the bar
    const maxToRight = scaleValue(scale, 250, 0.4); // Aplicando scaleValue
    const maxToLeft = scaleValue(scale, 0, 0.4); // Aplicando scaleValue
    function calculatePosition() {
        const percentageToPixels = (percentage * 2.5);
        if (percentageToPixels > maxToRight)
            return `${maxToRight.toString()}px`;
        if (percentageToPixels < maxToLeft)
            return `${maxToLeft.toString()}px`;
        return `${scaleValue(scale, percentageToPixels, 0.4).toString()}px`;
    }
    return (_jsxs("div", { style: { position: 'relative' }, children: [_jsx(Pallet, { style: { position: 'absolute', top: scaleValue(scale, '-6px'), left: calculatePosition() } }), _jsx("div", { style: {
                    width: scaleValue(scale, '284px', 0.4),
                    height: scaleValue(scale, '20px'),
                    background: 'linear-gradient(90deg, #FF0000 0%, #FFEC17 50%, #07FD72 100%)',
                    borderRadius: scaleValue(scale, 9),
                    border: `${scaleValue(scale, 1)}px #E0E0E0 solid`, // Aplicando scaleValue na borda
                } })] }));
}
