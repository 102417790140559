import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unsafe-optional-chaining */
import { Box, InputAdornment, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Input1 from '../../Atomos/Input1';
import { HelpTooltip } from '../../Atomos/HelpTooltip';
import { Formarters, scaleValue } from '../../utils'; // Importando scaleValue
const textStyle = {
    color: 'black',
    fontSize: 22,
    fontFamily: 'Poppins',
    fontWeight: '700',
    letterSpacing: 0.15,
    wordWrap: 'break-word',
};
export default function InputWithTitle2({ title, tooltipText, onlyPositiveNumber = true, initialAdornment, onChange, defaultValue, disabled, helperText, validator, scale = 1, }) {
    const [error, setError] = useState(null); // Estado para o erro
    const [actualValue, setActualValue] = useState(undefined); // Estado para o valor atual
    const [lastestValue, setLastestValue] = useState();
    const [wasEdited, setWasEdited] = useState(false);
    useEffect(() => {
        const isError = validator?.(actualValue || defaultValue || '');
        if (isError)
            setError(isError);
        else if (error !== null)
            setError(null);
    }, []);
    const handleInputChange = (event) => {
        const value = event.target.value.replace('.', ',');
        const isError = false;
        let validationError = null;
        // Executa a função de validação, se houver
        if (validator) {
            validationError = validator(value);
            setError(validationError); // Atualiza o estado do erro
        }
        if (onlyPositiveNumber) {
            let canUpdateValue = true;
            const normalizedValue = Formarters.parseNumberWithComma(value);
            if (normalizedValue < 0)
                canUpdateValue = false;
            if (isError)
                canUpdateValue = false;
            if (value === 'NaN')
                canUpdateValue = false;
            if (canUpdateValue || value === '') {
                setActualValue(Formarters.maskMoneyUnlimited(value));
                onChange?.(event, validationError);
                if (wasEdited === false) {
                    setWasEdited(true);
                }
            }
        }
        else {
            setActualValue(Formarters.maskMoneyUnlimited(value));
            onChange?.(event, validationError);
            if (wasEdited === false) {
                setWasEdited(true);
            }
        }
    };
    return (_jsxs(Box, { display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', sx: { gap: scaleValue(scale, '4px') }, children: [_jsxs(Box, { display: 'flex', gap: scaleValue(scale, '4px'), alignItems: 'center', flexWrap: 'wrap', children: [_jsx(Typography, { style: {
                            ...textStyle,
                            fontSize: scaleValue(scale, textStyle.fontSize), // Aplicando scale no tamanho da fonte
                        }, children: title }), _jsx(HelpTooltip, { tooltipText: tooltipText, size: scaleValue(scale, 18) }), " "] }), _jsx(Input1, { defaultValue: defaultValue, onChange: handleInputChange, disabled: disabled, value: actualValue, sx: { marginTop: scaleValue(scale, '4px'), width: '251.68px' }, error: !!error, helperText: error || helperText, isControlledComponent: true, InputProps: {
                    startAdornment: _jsx(InputAdornment, { position: 'start', children: initialAdornment }),
                }, onFocus: () => {
                    setLastestValue(actualValue);
                    setActualValue(''); // Limpa o valor ao focar
                }, onBlur: (e) => {
                    const v = e.target.value;
                    if (!v) {
                        if (wasEdited)
                            setActualValue('0');
                        else
                            setActualValue(lastestValue); // Restaura o valor padrão se o campo estiver vazio
                    }
                    setWasEdited(false);
                } })] }));
}
