import { jsx as _jsx } from "react/jsx-runtime";
import { TextField, styled } from '@mui/material';
import { useEffect, useState } from 'react';
const StyledTextField = styled(TextField)(({ theme }) => ({
    position: 'relative',
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#EEE',
        transition: 'background-color 0.3s ease-in-out',
        '&::after': {
            content: '""',
            position: 'absolute',
            left: '50%',
            bottom: 0,
            width: '0%',
            height: '3px',
            backgroundColor: 'black',
            transition: 'width 0.3s ease-in-out',
            transform: 'translateX(-50%)',
        },
        '&:hover::after': {
            width: '100%',
        },
        '&.Mui-focused::after': {
            width: '100%',
        },
        '&.Mui-disabled': {
            backgroundColor: 'rgb(215, 215, 215)',
            '&::after': {
                width: '0%', // Desabilita a animação da borda inferior quando desativado
            },
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Remove a borda padrão
    },
}));
export default function Input1({ inputType = 'text', isControlledComponent = true, value, error, helperText, disabled, defaultValue, ...props }) {
    const [internalDefaultValue, setInternalDefaultValue] = useState(defaultValue);
    useEffect(() => {
        if (disabled)
            setInternalDefaultValue(defaultValue);
    }, [defaultValue, disabled]);
    if (disabled) {
        return (_jsx(StyledTextField, { ...props, type: inputType, error: error, helperText: helperText, disabled: disabled, value: internalDefaultValue }));
    }
    if (isControlledComponent) {
        return (_jsx(StyledTextField, { ...props, type: inputType, error: error, helperText: helperText, disabled: disabled, value: value ?? internalDefaultValue }));
    }
    return (_jsx(StyledTextField, { ...props, type: inputType, error: error, helperText: helperText, disabled: disabled, defaultValue: defaultValue }));
}
