import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Drawer } from '@mui/material';
const initialStyle = {
    width: '400px',
    height: '500px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
    borderRadius: '16px',
    overflow: 'hidden',
};
export default function ChatDrawer({ open, onClose, drawerStyle, position }) {
    return (_jsxs(Drawer, { open: open, onClose: onClose, variant: 'persistent', transitionDuration: 0, sx: {
            '& .MuiPaper-root': {
                top: `${position.top}px`,
                left: `${position.left}px`,
                transition: 'none',
                ...initialStyle,
                ...drawerStyle,
            },
        }, children: [_jsx(Box, { sx: { display: 'flex', justifyContent: 'flex-end', padding: '1px 8px' }, children: _jsx(IconButton, { onClick: onClose, children: _jsx(CloseIcon, {}) }) }), _jsx(Box, { component: 'iframe', src: 'https://stec.cx/merlin/clientembeds/xskills?p=28bd2477-6d3d-4e69-8f03-7c9e8a32961c', sx: { width: '100%', height: '100%', border: 'none' } })] }));
}
