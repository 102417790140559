import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, List, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import ProductFieldError from '../../Atomos/ProductFieldError';
import GeneralFieldError from '../../Atomos/GeneralFieldError';
const translateDecisionSectionKey = (key) => {
    const translations = {
        financas: 'Finanças',
        contas_pagar: 'Contas',
        rh: 'RH',
        compras: 'Compra',
        vendas: 'Venda',
        marketing: 'Marketing',
    };
    return translations[key] || key;
};
const translateDecisionTypeKey = (key) => {
    const translations = {
        A: 'Produto A',
        B: 'Produto B',
        C: 'Produto C',
        D: 'Produto D',
        E: 'Produto E',
        aplicacao_1: 'Aplicação 1',
        aplicacao_2: 'Aplicação 2',
        emprestimo_normal: 'Empréstimo Normal',
        operacionais: 'Operacionais',
        vendedores: 'Vendedores',
    };
    return translations[key] || key;
};
const translateDecisionFieldKey = (key) => {
    const translations = {
        qtde_compra: 'Qtd. de Compra',
        tx_financiamento_vendedor_porc: 'Taxa de Financiamento',
        preco_venda: 'Preço de venda',
        antecipacao_pgtos: 'Antecipação de Pagamentos',
        desconto_duplicatas: 'Desconto de Títulos',
        ampliacao_m2: 'Ampliação M2',
        salarios: 'Salários',
        demitidos: 'Demitidos',
        admitidos: 'Admitidos',
        hora_extra: 'Hora Extra',
        deposito: 'Depósito',
        saque: 'Saque',
        valor: 'Depósito',
    };
    return translations[key] || key;
};
function createFieldsErrorMessage(fields) {
    return fields.map((element) => {
        const parts = element.split('.');
        return parts.length === 2
            ? { section: parts[0], field: parts[1] }
            : { section: parts[0], type: parts[1], field: parts[2] };
    });
}
const getGroupedSectionName = (key) => {
    const groupedSections = ['compras', 'vendas', 'marketing'];
    return groupedSections.includes(key)
        ? 'Compras, Vendas e Marketing'
        : translateDecisionSectionKey(key);
};
export default function AlertFieldsModal({ modalIsOpen, onClose, fieldsWithError, }) {
    const [formattedErrors, setFormattedErrors] = useState([]);
    useEffect(() => {
        if (modalIsOpen) {
            const errors = createFieldsErrorMessage(fieldsWithError);
            setFormattedErrors(errors);
        }
    }, [modalIsOpen, fieldsWithError]);
    const groupedErrors = formattedErrors.reduce((acc, error) => {
        const sectionName = getGroupedSectionName(error.section);
        if (!acc[sectionName]) {
            acc[sectionName] = [];
        }
        acc[sectionName].push(error);
        return acc;
    }, {});
    return (_jsxs(Dialog, { open: modalIsOpen, onClose: onClose, PaperProps: {
            sx: {
                overflow: 'auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '600px',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                padding: '0rem 1.5rem',
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            },
        }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", children: [_jsx(DialogTitle, { sx: { flex: 1, textAlign: 'center', padding: '25px 0px 0px 0px' }, children: _jsx(Typography, { variant: "h6", component: "div", sx: { fontWeight: 'bold' }, children: "Aten\u00E7\u00E3o" }) }), _jsx(IconButton, { onClick: onClose, sx: { position: 'absolute', top: 8, right: 8, color: '#000000' }, children: _jsx(CloseIcon, {}) })] }), _jsxs(DialogContent, { children: ["Verifique o(s) campo(s) antes de salvar:", Object.entries(groupedErrors).map(([section, errors]) => (_jsxs(Box, { sx: { marginTop: 2 }, children: [_jsx(Typography, { variant: "subtitle1", sx: { fontWeight: 'bold', marginBottom: 1 }, children: section }), _jsx(List, { sx: { width: '100%', bgcolor: '#fff' }, children: errors.map((error, index) => (_jsx(Box, { width: "100%", children: section === 'Compras, Vendas e Marketing' ? (_jsx(ProductFieldError, { section: translateDecisionSectionKey(error.section), field: translateDecisionFieldKey(error.field), type: translateDecisionTypeKey(error.type || '') })) : (_jsx(GeneralFieldError, { field: translateDecisionFieldKey(error.field), type: translateDecisionTypeKey(error.type || '') })) }, index))) })] }, section)))] })] }));
}
