import { jsx as _jsx } from "react/jsx-runtime";
import { Card } from '@mui/material';
const style = {
    width: '100%',
    height: '100%',
    backgroundColor: '#FAFAFA',
    borderRadius: '8px',
};
export default function OutlinedCard({ sx, children, ...props }) {
    return (_jsx(Card, { variant: 'outlined', sx: { ...style, ...sx }, ...props, children: children }));
}
