import { Typography, CircularProgress, useMediaQuery, Box } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import React, { useEffect } from 'react';
import { Box1, TimeTracking, Button3, Chat } from '@repo/ui';
import { EtapaDTO } from '../../../dto/etapaDTO';
import { calculateMinutesUntilDate } from '../../../lib/utils';

export type BottomContainerProps = {
  sendButtonActive: boolean;
  boxStyle?: React.CSSProperties;
  etapa: EtapaDTO;
  onErrorTimeIsEnd: boolean;
  confirmValues: boolean;
  sendFunction: () => Promise<void>;
  nextStepFunction: () => Promise<void>;
  refreshDecision: () => void;
};

const deactiveButton: React.CSSProperties = {
  color: 'white',
  fontSize: '11px',
  fontFamily: 'Poppins',
  fontWeight: '600',
  letterSpacing: '0.15',
  wordWrap: 'break-word',
  width: '150px',
  background: '#666666',
};

const nextStepButton: React.CSSProperties = {
  color: 'white',
  fontSize: '11px',
  fontFamily: 'Poppins',
  fontWeight: '600',
  letterSpacing: '0.15',
  wordWrap: 'break-word',
  marginLeft: '15px',
  width: '150px',
  background: '#007B36',
};

const refreshStepButton: React.CSSProperties = {
  color: 'white',
  fontSize: '11px',
  fontFamily: 'Poppins',
  fontWeight: '600',
  letterSpacing: '0.15',
  wordWrap: 'break-word',
  width: '150px',
  background: '#0077B6',
};

export default function BottomContainer({
  etapa,
  onErrorTimeIsEnd,
  confirmValues,
  sendButtonActive,
  boxStyle,
  sendFunction,
  nextStepFunction,
  refreshDecision,
}: BottomContainerProps): JSX.Element {
  const [isSending, setIsSending] = React.useState(false);
  const [isTimeOver, setIsTimeOver] = React.useState(false);
  const ultraMinDesktopSize = useMediaQuery('(min-width: 1020px)');
  const minDesktopSize = useMediaQuery('(min-width: 1200px)');
  const mediumDesktopSize = useMediaQuery('(min-width: 1440px)');
  const largeDesktopSize = useMediaQuery('(min-width: 1920px)');

  // useEffect(() => {
  //   setIsTimeOver(calculateMinutesUntilDate(etapa.cronometro) <= 0);
  //   localStorage.setItem('isTimeOver', (calculateMinutesUntilDate(etapa.cronometro) <= 0).toString());
  //   window.dispatchEvent(new Event('isTimeOverChange'));
  // }, [etapa]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    return date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      dateStyle: 'short',
      timeStyle: 'short',
    });
  };

  useEffect(() => {
    if (etapa.cronometro.tipo === 'expirada') {
      setIsTimeOver(true);
      localStorage.setItem('isTimeOver', false.toString());
    } else {
      setIsTimeOver(false);
      localStorage.setItem('isTimeOver', false.toString());
    }

    window.dispatchEvent(new Event('isTimeOverChange'));
  }, [etapa]);

  const handleNextStepFunction = async () => {
    localStorage.setItem('ignoreBeforeUnload', 'true');
    await nextStepFunction();
  };

  const handleSendClick = async () => {
    setIsSending(true); // Inicia o estado de loading
    try {
      await sendFunction();
    } finally {
      setIsSending(false); // Termina o estado de loading
    }
  };

  const handleSendNext = async () => {
    setIsSending(true); // Inicia o estado de loading
    try {
      await handleNextStepFunction();
    } finally {
      setIsSending(false); // Termina o estado de loading
    }
  };

  const onTimeEnd = () => {
    setIsTimeOver(true);
    localStorage.setItem('isTimeOver', false.toString());
    window.dispatchEvent(new Event('isTimeOverChange'));
  };

  useEffect(() => {
    if (onErrorTimeIsEnd) {
      onTimeEnd();
    }
  }, [onErrorTimeIsEnd]);

  const endText = () => {
    if (largeDesktopSize) return 'Final da Etapa:';
    if (mediumDesktopSize) return 'Final da Etapa:';
    if (minDesktopSize) return 'Final Etapa:';
    if (ultraMinDesktopSize) return 'Final Etapa:';
    return '';
  };

  function renderButton() {
    return (
      <>
        <Button3
          onClick={handleSendClick}
          disabled={isSending || !sendButtonActive || confirmValues} // Desativa o botão se estiver enviando ou se o botão não estiver ativo
          child={
            isSending || confirmValues ? (
              <CircularProgress size={24} color='inherit' /> // Indicador de loading dentro do botão
            ) : (
              'Salvar'
            )
          }
          sx={sendButtonActive || isSending || confirmValues ? { width: '150px' } : deactiveButton}
        />

        <Button3
          onClick={handleSendNext}
          disabled={isSending || confirmValues} // Desativa o botão se estiver enviando ou se o botão não estiver ativo
          child={
            isSending || confirmValues ? (
              <CircularProgress size={24} color='inherit' /> // Indicador de loading dentro do botão
            ) : (
              'Próxima Etapa'
            )
          }
          sx={nextStepButton}
        />
      </>
    );
  }

  return (
    <Box1
      sx={{
        width: '1318px',
        height: '71px',
        padding: '15px 24px 15px 28px',
        alignItems: 'center',
        justifyContent: 'space-around',
        background: 'white',
        ...boxStyle,
      }}
    >
      <Box1 sx={{ height: '28px', width: '229px', padding: '7px 20px', alignItems: 'center' }}>
        <CalendarTodayOutlinedIcon />
        <Typography>{etapa.numero_etapa}º Trimestre</Typography>
      </Box1>
      <Box1 sx={{ height: '28px', width: '576px', padding: '7px 20px', alignItems: 'center' }}>
        <CalendarTodayOutlinedIcon />
        <Typography>{endText()}</Typography>
        <div
          style={{
            width: 'auto',
            height: '100%',
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 1,
            paddingBottom: 1,
            background: '#07FD72',
            borderRadius: 6,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: 'black',
              fontSize: 15,
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {isTimeOver || etapa.cronometro.tipo === 'expirada' ? 'Etapa Finalizada' : `${formatDate(etapa.data_final)} BSB`}
          </div>
        </div>
      </Box1>

      <Button3
        onClick={() => refreshDecision()}
        disabled={isSending || confirmValues}
        child='Atualizar'
        sx={isSending || confirmValues ? deactiveButton : refreshStepButton}
      />
      <Box sx={{ display: 'flex' }}>
        {renderButton()}
        <Chat />
      </Box>
    </Box1>
  );
}
