import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { DropdownSelect } from '../../Atomos';
export default function NewsHeader({ gameName, gameEtapa, hasSelect, onUpdateState, options }) {
    const [dropdownValues, setDropdownValues] = useState({ label: '', value: '' });
    useEffect(() => {
        if (options?.length) {
            const etapaValue = gameEtapa?.toString();
            const foundOption = options.find((option) => option.value === etapaValue);
            if (foundOption) {
                setDropdownValues(foundOption);
            }
        }
    }, [options]);
    const handleChangeSelect = (option) => {
        setDropdownValues(option);
        onUpdateState?.(parseInt(option.value));
    };
    return (_jsx(Box, { mt: 1, sx: {
            backgroundColor: '#00AB4A',
            width: '85%',
            minHeight: '95px',
            border: '2px solid #007B36',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            minWidth: { xs: '', sm: '', md: '700px', lg: '850px', xl: '850px' },
        }, children: _jsxs(Grid, { container: true, alignItems: 'center', justifyContent: 'space-between', px: 2, pt: { xs: 2, sm: 2, md: 0, lg: 0 }, children: [_jsx(Grid, { item: true, xs: 6, sm: 6, md: 4, lg: 4, container: true, justifyContent: 'flex-start', children: _jsx(Typography, { fontSize: 'clamp(1rem, 2.5vw, 1.125rem)', fontFamily: 'Poppins', fontWeight: 600, color: '#fff', lineHeight: '25px', textAlign: 'left', children: gameName }) }), _jsx(Grid, { sx: { order: { xs: 1, sm: 1, md: 0, lg: 0, xl: 0 } }, py: { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 }, item: true, xs: 12, sm: 12, md: 4, lg: 4, container: true, justifyContent: 'center', children: _jsx(Box, { sx: { textAlign: 'center' }, children: _jsx("img", { src: '/xskill-news.png', width: '300px', height: '46px', alt: 'news-img' }) }) }), _jsx(Grid, { item: true, xs: 6, sm: 6, md: 4, lg: 4, container: true, justifyContent: 'flex-end', children: hasSelect ? (_jsx(DropdownSelect, { boxStyle: { width: '65%' }, label: '', options: options || [], dropdownValue: dropdownValues, onSelect: (option) => handleChangeSelect(option) })) : (_jsx(Typography, { fontSize: 'clamp(1rem, 2.5vw, 1.125rem)', fontFamily: 'Poppins', fontWeight: 600, color: '#fff', lineHeight: '25px', textAlign: 'right', children: gameEtapa && `Etapa ${gameEtapa}` })) })] }) }));
}
