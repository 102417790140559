import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { IconButton } from '@mui/material';
import DefaultButtonImage from './DefaultButtonImage.jfif';
const initialStyle = {
    width: 42,
    height: 42,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    marginLeft: '10px',
    marginRight: '20px',
};
const ChatButton = forwardRef(({ onClick, buttonStyle, chatButtonImage, ...props }, ref) => {
    return _jsx(IconButton, { ref: ref, onClick: onClick, sx: { ...initialStyle, ...buttonStyle, backgroundImage: `url(${chatButtonImage || DefaultButtonImage})` }, ...props });
});
export default ChatButton;
