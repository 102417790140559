import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
const style = {
    display: 'flex',
    height: '126px',
    padding: '4px 97px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
    borderRadius: '15px',
    border: '1.5px solid #E0E0E0',
};
export default function Box1({ sx, children, ...props }) {
    return (_jsx(Box, { sx: { ...style, ...sx }, ...props, children: children }));
}
