import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ListItem } from "@mui/material";
export default function GeneralFieldError({ type, field, }) {
    return (_jsxs(ListItem, { sx: {
            bgcolor: '#f4f4f4',
            borderRadius: '15px',
            marginBottom: '10px',
            minWidth: '150px',
        }, children: [type && (_jsx(Box, { sx: {
                    flexGrow: 1,
                    flexBasis: '50%',
                    textAlign: 'start',
                }, children: _jsx("strong", { children: type }) })), _jsxs(Box, { sx: {
                    flexGrow: 1,
                    flexBasis: '50%',
                    textAlign: 'start',
                }, children: ["Campo:", _jsxs("strong", { children: [" ", field] })] })] }));
}
